import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
Vue.prototype.$axios = axios

import './plugins/element.js'
import {
  Message
} from 'element-ui'
Vue.prototype.$message = Message


Vue.config.productionTip = false
import '../src/css/global.css'


import nullBox from "./components/nullBox.vue";
Vue.component("nullBox", nullBox)

import endBox from "./components/endbox.vue";
Vue.component("endBox", endBox)

import "./utils/flexible"
Vue.directive('throttle', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      el.style.pointerEvents = 'none';
      if (!el.disabled) {
        setTimeout(() => {
          el.style.pointerEvents = 'auto'
        }, binding.value || 500)
      }
    })
  }
})

Vue.filter('dateFormat', function (value) {
  if (!value) {
    return ''
  }
  return   value.split(" ")[0]
})

// window.addEventListener('popstate', function () {
//   history.pushState(null, null, document.URL)
// })
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
