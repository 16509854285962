<template>
    <div ref="myChart" id="charts3"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';

export default {
    name: 'MyChart3',
    props: {
        pageData: Object
    },
    data() {
        return {
            is1920: false,
            chartsSize: 100,
            // XData: [
            //     '汽油储运', '金属腐蚀与防护', '管道防腐层', '管道阴极保护', '水工保护', '电工学', '管道施工',
            //     '安全及环境保护', '管道保卫', '管道内腐蚀控制', '管道干扰与防护', '焊接',
            //     '管材', '管体缺陷及修复', '管道完整性', '管道应急抢修', '管道工程', '应急抢修设备操作',
            //     '突发事件前期处置', '电子技术', '操作与维护有关设备、设施', '安全保障', '综合管理', '管道保护和检测设备', '防腐层防腐', '管道缺陷及修复', '履职能力', '法律法规', '标准规范'
            // ],
            XData: [],
            YData: [],
            // YData: ['初级', '中级', '高级', '技师'],
            // chartsData: [
            //     [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
            //     [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
            //     [0, 22, 50], [0, 23, 100]]
            //     .map(function (item) {
            //         return [item[1], item[0], item[2] || '-'];
            //     }),
            chartsData: [],
            pageInfo: {},
            pageColor: [],
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
        // this.initChart();

    },
    watch: {
        immediate: true,
        deep: true,
        pageData: {
            handler: function (val, oldval) {
                if (val.pageInfo && val.pageColor) {
                  
                    this.pageInfo = val.pageInfo
                    this.pageColor = val.pageColor

                    console.log(this.pageInfo);
                    console.log(this.pageColor);

                    this.chartsInfo = {
                        tooltip: {
                            position: 'top'
                        },
                        grid: {
                            height: '50%',
                            top: '10%'
                        },
                        xAxis: {
                            type: 'category',
                            data: val.pageInfo.x_data,
                            splitArea: {
                                show: true
                            },
                            axisLabel: {
                                rotate: 45,

                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: val.pageInfo.y_data,
                            splitArea: {
                                show: true
                            }
                        },
                        visualMap: {
                            min: 0,
                            max: 100,

                            itemHeight: 200,
                            calculable: true,
                            orient: 'horizontal',
                            left: 'center',
                            bottom: '5%',
                            // color: ['#8cffe3', '#00d8ff'],
                            color: ['#8cffe3', '#00d8ff'],
            
                            // color: function (v) {
                            //     
                            //     // 当数据为 0 时返回透明色
                            //     if(v==0){
                            //         return "red"
                            //     }else {
                            //         return ['#8cffe3', '#00d8ff']
                            //     }
                            //     return v === 0 ? 'transparent' : '#eb8146';
                            // },
                            textStyle: {
                                color: "#fff"
                            },
                        },
                        series: this.setChartsData(val.pageInfo.data)
                    }

                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    methods: {
        setChartsData(data) {
            let tempData = data.map(function (item) {
                return [item[1], item[0], item[2]];
            })
            
            let temp = {
                name: '',
                type: 'heatmap',
                data: tempData,
                label: {
                    show: true,
                    formatter: function (params) {
                     
                        // 
                        // if(params.data[1] == 0){
                        //     return params.data[2] + '%';    
                        // }else {
                        //     return params.data[2];    
                        // }


                        return params.data[2] + '%';

                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }

            console.log(temp);
            return temp

        },
        initChart(data) {
            console.log(data);

            var that = this
            const myChart = echarts.init(this.$refs.myChart,);
            myChart.setOption(
                data
            );
            this.$nextTick(() => {
                console.log("myChart.resize()");
                myChart.resize()
            })
        }
    }
};
</script>
<style></style>

