<template>
  <div style=" height: 100%;">
    <div class="result_box">
      <div class="left">
        <div class="press_box">
          <el-progress type="dashboard" :percentage="percentage" color="#695fee" :stroke-width="14" :show-text="false"
            define-back-color="#cdd8fd"></el-progress>
          <div class="info_box">
            <div class="point">{{ pageInfo.score }}分</div>
            <div class="text">得分</div>
          </div>
        </div>
        <div class="exam_info_box">
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.ques_all }}</div>
            <div class="text2">一共</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.already_answer }}</div>
            <div class="text2">已答题</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.not_answer }}</div>
            <div class="text2">未答题</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.take_time }}</div>
            <div class="text2">用时</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title_box">
          <div class="text">得分情况</div>
          <div class="line"></div>
        </div>
        <div class="point_box">
          <div class="point_item">
            <div class="title">题型</div>
            <div class="title">总数</div>
            <div class="title">正确题数</div>
            <div class="title">正确率</div>
          </div>
          <div class="point_item" v-for="(item, index) in pageInfo.ques_type">
            <div>{{ item.title }}</div>
            <div>{{ item.ques_count }}</div>
            <div>{{ item.correct_count }}</div>
            <div>{{ item.accuracy }}%</div>
          </div>
        </div>
        <div class="btn_box" :style="{justifyContent:isMoKao || isZhuanKao ? null : 'flex-end'}">
        	<div v-if="isMoKao || isZhuanKao" class="back_btn" @click="lookWrong" v-throttle>查看错题</div>
        	<div v-if="isMoKao || isZhuanKao" class="back_btn" @click="lookAll" v-throttle>查看全卷</div>
        	<div class="back_btn" @click="pageBack" v-throttle>返回</div>
        </div>
      </div>
      <div class="line_box">
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _examResult } from "../api/resultPage"
import * as global from '@/mixins/global.js';
export default {
  components: {},
  created() {
    this.examResult()
  },

  mounted() { },
  data() {
    return {
      percentage: 0,
      pageInfo: {}
    };
  },
  computed: {
  	isMoKao() {
  		return this.$route.query.examType == global.ExamType.MoKao;
  	},
    isZhuanKao() {
      return this.$route.query.examType == global.ExamType.ZhuanKao;
    },
  },
  methods: {
    async examResult() {
      let params = {
        exam_id: window.localStorage.getItem("exam_id")
      }
      let res = await _examResult(params)
      this.pageInfo = res.data

      this.percentage =   this.GetPercent( this.pageInfo.already_answer, this.pageInfo.ques_all)
    },
    pageBack() {
      if (this.isZhuanKao) {
        this.$router.replace({ path: "/Index/ExamTab"});
      } else {
        this.$router.replace({ path: "/Index/TYKSList", query:{
          ...this.$route.query
        } });
      }
    },
    GetPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) ;
    },
    lookWrong() {
    	const exam_id = window.localStorage.getItem("exam_id");
    	window.localStorage.setItem("lookObjectExamID", exam_id);
    	this.$router.push({
    		name: 'LookWrong'
    	});
    },
    lookAll() {
    	const exam_id = window.localStorage.getItem("exam_id");
    	window.localStorage.setItem("lookObjectExamID", exam_id);
    	this.$router.push({
    		name: 'LookAll'
    	});
    }
  },


};
</script>
<style scoped>
.result_box {
  width: 58%;
  height: 63%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result_box>div {
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  border-radius: 40px;
}

.result_box>.left {
  margin-right: 10px;
  background: linear-gradient(162.49deg, rgba(155, 178, 250, 1) 5.03%, rgba(211, 222, 255, 0.37) 111.82%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

}

.result_box>.right {
  margin-left: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.result_box>.right .btn_box {
		align-self: stretch;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 47px;
	}

.press_box {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 36px;
}

.info_box {
  width: 40%;
  height: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 10px;
}

.info_box .point {
  color: rgba(100, 110, 255, 1);
  font-weight: 700;
  font-size: 30px;
}

.info_box .text {
  color: rgba(114, 123, 142, 1);
  font-size: 14px;
}

.exam_info_box {
  width: 90%;
  height: 108px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px 10px 30px;
}

.exam_info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam_info_item .text1 {
  color: rgba(29, 37, 54, 1);
  font-size: 28px;
}

.exam_info_item .text2 {
  color: rgba(114, 123, 142, 1);
  font-size: 12px;
  margin-top: 14px;
}

.title_box {
  position: relative;
}

.title_box .text {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  position: relative;
  z-index: 2;
}

.title_box .line {
  width: 112%;
  height: 17px;
  border-radius: 29px;
  background: rgba(155, 178, 250, 1);
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translate(-50%, 0);
}

.point_box {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
  box-sizing: border-box;
  padding: 0 30px;
}

.point_item {
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.point_item div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(114, 123, 142, 1);
  font-size: 16px;
}

.point_item .title {
  color: rgba(114, 123, 142, 1) !important;
  font-size: 20px !important;
}

.line_box {
  width: 110px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
}

.line_box .line {
  width: 100%;
  height: 20px;
  position: relative;
}

.line_box .line .yuan {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgba(155, 178, 250, 1);
  position: absolute;
}

.line_box .line .yuan1 {

  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.line_box .line .yuan2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.line_box .line .fang {
  width: 102px;
  height: 13px;
  border-radius: 21px;
  background: rgba(100, 110, 255, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.back_btn {

  width: 100px;
  height: 38px;
  display: flex;
  place-content: center;
  place-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  align-self: flex-end;
  margin-top: 20px;
  cursor: pointer;
}
</style>
