import { request } from "../utils/request"


export function _getMKResultList(data) {
    return request({
        url: 'stuwebapi/study.practice/getPracticeGradeList',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}
export function _getTKResultList(data) {
    return request({
        url: 'stuwebapi/study.exam/getExamGradeList',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}

export function _getWrongList(data) {
    return request({
        url: 'stuwebapi/study.practice/getPracticeDetails',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}


export function _getWrongInfo(data) {
    return request({
        url: 'stuwebapi/train.test/checkErrors',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}

export function _reportSubject(data) {
    return request({
        url: 'stuwebapi/study.report/exportPaper',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}

