<template>
  <div class="my_title_box">
    <div class="sj_1"></div>
    <div class="sj_2"></div>
    <div class="my_title_left_point">
      <div class="point_1"></div>
      <div class="point_2"></div>
      <div class="point_1"></div>
    </div>
    <div class="my_title_right_box">
      <div class="my_title_right_item"></div>
      <div class="my_title_right_item"></div>
      <div class="my_title_right_item"></div>
    </div>
    <div class="my_title_line"></div>
   <div class="my_title_text" v-if="title">{{title}}</div>
  </div>
</template>
  

<script>

export default {
  name: "myTitleBox",
  components: {},
  created() {

  },
  props: {
    title: String
  },
  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style >
.my_title_box {
  width: 100%;
  height: .5rem;
  position: relative;
  box-sizing: border-box;
  padding-left: .375rem;
  padding-right: .8125rem;
  background: linear-gradient(90deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
}
.my_title_text {
  width: 100%;
  height: .5rem;
  line-height:.5rem;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.sj_1 {
  width: 0;
  height: 0;
  border-top: .1rem solid rgba(140, 253, 255, 1);
  border-right: .1rem solid transparent;
  position: absolute;
  left: 0;
  top: 0;
}
.sj_2 {
  width: 0;
  height: 0;
  border-bottom: .1rem solid rgba(26, 84, 227, 1);
  border-left: .1rem solid transparent;
  position: absolute;
  right: 0;
  bottom: 0;
}
.my_title_line {
  width: 100%;
  height:1px;
  background: linear-gradient(90deg, rgba(140, 253, 255, 0) 0%, rgba(140, 253, 255, 1) 99.81%);
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(180deg);
}
.my_title_left_point {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;


}
.my_title_left_point .point_1 {
  width: 4px;
  height: 4px;
  transform: rotate(45deg);
  background-color: rgba(140, 253, 255, 1);
}
.my_title_left_point .point_2 {
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: rgba(140, 253, 255, 1);
  margin: 7px 0;
  background: rgba(30, 83, 225, 1);
  box-shadow: 0px 2px 4px rgba(140, 253, 255, 1) inset;
}
.my_title_right_box {
  display: flex;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0,-50%) skewX(-40deg);
}
.my_title_right_box .my_title_right_item {
  width: .075rem;
  height: .175rem;
  background: linear-gradient(90deg, rgba(34, 76, 163, 1) 0%, rgba(34, 99, 215, 1) 100%);
  box-shadow: 0px 0px 4px rgba(16, 251, 255, 1) inset;
  margin-left: .05rem;
  
}
</style>

