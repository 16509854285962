<template>
  <div>
    <div class="start_box">
      <div class="left">
        <div class="title">考试信息</div>
        <div class="info_item">考试名称：{{ pageData.grant_info.title }}</div>
        <div class="info_item">考试时间：{{ pageData.grant_info.start_time.format }}</div>
        <div class="info_item">禁止进入考试时间：{{ pageData.grant_info.end_time.format }}</div>
        <div class="info_item">考试时长：{{ pageData.grant_info.exam_duration }}分钟</div>
        <div class="info_item">合格分数：{{ pageData.grant_info.qualified_score }}分</div>
        <div class="info_item">
          允许进入次数：{{ pageData.recordNum }}/{{ pageData.grant_info.allow_num }}
        </div>
        <div class="line"></div>
        <div class="title">学员信息</div>
        <div class="info_item">单位：{{ pageData.stu_info.work_unit }}</div>
        <div class="info_item">考生姓名：{{ pageData.stu_info.stu_name }}</div>
        <div class="info_item">考生性别：{{ pageData.stu_info.stu_sex.name }}</div>
        <div class="info_item">手机号码：{{ pageData.stu_info.stu_tel.val }}</div>
        <div class="info_item">身份证号码：{{ pageData.stu_info.stu_code.val }}</div>

      </div>
      <div class="right">
        <div class="title">考生须知</div>
        <div class="info_box">
          <div class="info_item">一、考试系统只能通过鼠标操作</div>
          <div class="info_item">
            二、通过点击鼠标选中相应的答案，选中后点击下一题继续答题
          </div>
          <div class="info_item">
            三、点击"上一题"、"下一题"或者答题卡的某一题进行题目的切换；点击"提交试卷"将提交试卷，交卷后不能再进行修改，并立即给出考试成绩
          </div>
          <div class="info_item">
            四、请安装谷歌浏览器或者最新360极速浏览器进行考试
          </div>
          <div class="info_item">
            五、考试时间到才能进入进行答题，请耐心等待
          </div>
          <div class="info_item">
            六、考试前请关闭电脑其他软件，避免因为意外造成考试退出，一旦退出则视为交卷
          </div>
          <div class="info_item">
            七、考试时间到，若考生仍未提交试卷，系统将自动提交
          </div>
        </div>
        <div class="btn_box">
          <div class="btn btn_1">
            {{ overTimeText }}
          </div>

          <!-- <div class="btn btn_2" :class="[btnIsCanClick == false ? 'btn_disable' : '']" v-if="pageData.recordNum > 0"
            @click="examAgin()">
            申请重考
          </div> -->
          <div class="btn btn_2" :class="[btnIsCanClick == false ? 'btn_disable' : '']" @click="jumpExam()" v-throttle>
            确认无误，进入考试
          </div>
        </div>
      </div>
    </div>

    <div class="page_end_fixed">
      <endBox></endBox>
    </div>
    <div class="shade" v-if="isShadeShow">
      <div class="again_box">
        <div class="box_title">申请重考
          <img src="https://resources.sxsaas.com/static/jigou-saas-app/close2.png" alt=""
            @click.stop.prevent="closeShade">
        </div>
        <div class="select_box">
          <el-radio-group v-model="radio" @input="radioChange">
            <el-radio class="acitonButton" size="medium" label="1">继续</el-radio>
            <el-radio class="acitonButton" size="medium" label="2">重考</el-radio>
          </el-radio-group>
        </div>
        <div class="reason_box">
          <div class="title">原因</div>
          <el-input class="reason_text" type="textarea" :rows="2" placeholder="请输入内容" v-model="againExam.text" rows="6"
            resize='none'>
          </el-input>
        </div>
        <div class="send_btn" @click.stop.prevent="sendAgain">
          确认
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _getExamInfo, _getExamQues, _enterExam } from "../../api/TYKSStart"
export default {
  components: {},
  created() {
    window.localStorage.removeItem("exam_ques")
    this.getExamInfo()
    this.checkTime();
    this.getExamQues()
  },

  mounted() { },
  data() {
    return {
      examID: null,
      isShadeShow: false,
      radio: '1',
      btnIsCanClick: false,
      overTimeText: "",
      timeer: null,
      pageData: {
        "error_status": "",
        "error_msg": "",
        "recordNum": "",
        "grant_info": {
          "id": "",
          "title": "",
          "start_time": {
            "val": "",
            "format": ""
          },
          "end_time": "",
          "allow_num": "",
          "allow_late": "",
          "is_finish": "",
          "stu_type": {
            "val": "",
            "format": ""
          },
          "pro_id": "",
          "exam_duration": "",
          "qualified_score": ""
        },
        "stu_info": {
          "id": "",
          "stu_name": "",
          "stu_tel": {
            "val": "",
            "format": ""
          },
          "stu_code": {
            "val": "",
            "format": ""
          },
          "stu_sex": {
            "val": "",
            "name": ""
          },
          "work_unit": ""
        }
      },
      diffDate: {
        day: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      againExam: {
        type: 1,
        text: ""
      }
    };
  },
  methods: {
    async getExamInfo() {
      let params = {
        grant_id: window.localStorage.getItem("grantID"),
        isShowErr: true,
      }
      let res = await _getExamInfo(params)

      this.pageData = res.data
      console.log(res.data);

    },
    checkTime() {
      let that = this;
      this.timeer = setInterval(() => {
        // 
        // 获取当前时间戳
        const nowTime = new Date().getTime();
        // const nowTime = 1715467521000;
        // 拿考试开始的时间戳 减去当前时间戳
        const gapTime = parseInt(this.pageData.grant_info.start_time.val) - parseInt(nowTime / 1000);
        // 拿考试禁止进入的时间戳 减去当前时间戳
        const diffTime = parseInt(this.pageData.grant_info.end_time.val) - parseInt(nowTime / 1000);

        // 时间到了显示文本 关闭定时器 按钮状态置为可用
        if (gapTime <=0   && diffTime >= 0) {
          that.overTimeText = "考试可以开始";
          that.btnIsCanClick = true;
        } else {
          // 时间未到 就拿时间戳转化成 年月日 时分秒
          if (gapTime > 0 &&diffTime > 0) {
            that.getTime_s(gapTime);
            return
          }
          // 如果已经禁止进入  则提示禁止进入
          if (diffTime < 0) {
            that.btnIsCanClick = false;
            that.overTimeText = "考试已禁止进入";
            clearInterval(that.timeer);
            return
          }
        }
      }, 1000);
    },
    async getExamQues() {
      let params = {
        grant_id: window.localStorage.getItem("grantID"),
        isShowErr: true,
      }
      let res = await _getExamQues(params)

      if (res.data) {
        this.examID = res.data.exam_id
        window.localStorage.setItem("exam_id", res.data.exam_id)

        let temp = JSON.stringify(res.data)
        window.localStorage.setItem("exam_ques", temp)
      }
    },
    // 时间戳转换格式
    getTime_s(timestamp) {
      timestamp = timestamp;

      let day = parseInt(timestamp / (60 * 60 * 24));
      let hours = parseInt((timestamp % (60 * 60 * 24)) / (60 * 60));
      let minutes = parseInt((timestamp % (60 * 60)) / 60);
      let seconds = parseInt(timestamp % 60);

      this.diffDate.day = day;
      this.diffDate.hours = hours < 10 ? "0" + hours : hours;
      this.diffDate.minutes = minutes < 10 ? "0" + minutes : minutes;
      this.diffDate.seconds = seconds < 10 ? "0" + seconds : seconds;

      this.overTimeText = "离考试开始还有"
      if (day != 0) {
        this.overTimeText += this.diffDate.day + "天"
      }
      if (hours != 0) {
        this.overTimeText += this.diffDate.hours + "小时"
      }
      this.overTimeText = this.overTimeText + this.diffDate.minutes + "分钟" + this.diffDate.seconds + "秒"
    },
    examAgin() {
      if (this.btnIsCanClick == true) {
        this.isShadeShow = true
      }
    },
    closeShade() {
      this.isShadeShow = false
    },
    sendAgain() {
      console.log("申请重考");
    },
    async jumpExam() {
      // 果如按钮可用
      if (this.btnIsCanClick == true) {
        if(this.timeer){
          clearInterval(this.timeer);
        }
        let params = {
          grant_id: window.localStorage.getItem("grantID"),
          isShowErr: true,
          exam_id: this.examID
        }
        let res = await _enterExam(params)
        window.localStorage.setItem("grantInfo", JSON.stringify(res.data))
        if (window.localStorage.getItem("exam_ques")) {
          window.sessionStorage.setItem("isCanJumpExam", "true")
          this.$router.replace({ name: "TYKSPage" });
        } else {
          this.$message({ showClose: true, message: "试题未发放或未成功加载，请联系管理员", type: "error" });
        }
      }
    },
    radioChange(e) {
      this.againExam.type = parseInt(e)
      console.log(e);
    }
  },
};
</script>
<style scoped>
.start_box {
  width: 1200px;
  height: 80%;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.left {
  width: 438px;
  height: 100%;
  background: rgba(238, 242, 254, 1);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px;
}

.left .title {
  color: rgba(29, 37, 54, 1);
  font-weight: 700;
  font-size: 18px;
  margin: 40px 0 40px 0;
}

.left .info_item {
  color: rgba(29, 37, 54, 1);
  font-size: 16px;
  margin-bottom: 26px;
}

.line {
  width: 100%;
  height: 1px;
  margin-bottom: 60x;
}

.right {
  flex: 1;
  height: 100%;
  margin-left: 16px;
  background: rgba(238, 242, 254, 1);
  box-sizing: border-box;
  padding: 0 20px;
}

.right .title {
  width: 100%;
  text-align: center;
  color: rgba(22, 27, 38, 1);
  font-weight: 700;
  font-size: 18px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.info_box {
  width: 100%;
  height: 70%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
}

.info_box .info_item {
  margin-bottom: 20px;
}

.info_box .info_item:last-child {
  margin-bottom: 0;
}

.btn_box {
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 7px 18px;
  border: 1px solid rgba(100, 110, 255, 1);
  border-radius: 4px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}

.btn_1 {
  color: rgba(29, 37, 54, 1);
}

.btn_2 {
  color: rgba(255, 255, 255, 1);
  background: rgba(100, 110, 255, 1);
}

.btn_disable {
  border: 1px solid #ccc !important;
  background: #ccc !important;
  color: rgb(132, 132, 132) !important;
}

.shade {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
}

.again_box {
  width: 480px;
  height: 440px;
  border-radius: 20px;
  overflow: hidden;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.again_box .box_title {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  position: relative;
  font-size: 20px;
}

.again_box img {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.select_box {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

:deep(.el-radio__inner) {
  width: 18px;
  height: 18px;
}

.reason_box {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 10px 20px 20px 20px;
}

.reason_box .title {
  color: rgba(114, 123, 142, 1);
  font-size: 16px;
}

.reason_box .reason_text {
  margin-top: 10px;
}

.send_btn {

  width: 100px;
  height: 35px;
  display: flex;
  place-content: center;
  place-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  align-self: center;
  margin-bottom: 30px;
  cursor: pointer;
}
</style>
