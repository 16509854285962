<template>
    <div class="bs_main_box">
        <div v-if="dateArr.length > 0">
            <!-- <div class="hint">当前功能正在开发...</div> -->
            <div class="tool_box">
                <div class="left">
                </div>
                <div class="right" v-if="dropArr.length > 0">
                    <el-select v-model="selectValue" placeholder="请选择" popper-class="selectstyle_dark" @change="changeDrop"
                        style="margin-right: 0.2rem;">
                        <el-option v-for="item in dropArr" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <el-date-picker v-model='dateValue' type='date' placeholder='请选择日期' popper-class="datestyle_dark">
                </el-date-picker> -->
                    <el-date-picker v-model='dateValue' type='date' placeholder='请选择日期' popper-class="datestyle_dark"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="changeDate">
                    </el-date-picker>
                </div>
            </div>
            <div class="row_1 row_1_bg">
                <myBorder></myBorder>
                <div class="study_data_list" v-if="pageInfo.base_info">
                    <div class="study_item" v-for="(item, index) in pageInfo.base_info" :key="index">
                        <div class="text1">{{ item.data }}</div>
                        <div class="text2">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div class="row_1">
                <div class="row_2 row_2_size1">
                    <myTitleBox title="各题型已学题与总数对比及正确率"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;">
                            <myBorder></myBorder>
                            <MyChart4 :pageData="{ pageInfo: pageInfo.type_right_accuracy }"></MyChart4>

                        </div>
                    </div>
                </div>
                <div class="row_2 row_2_size1">
                    <myTitleBox title="每天的学习时间曲线"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;">
                            <myBorder></myBorder>
                            <MyChart5
                                :pageData="{ pageInfo: pageInfo.everyday_study, pageColor: ['#41ABFF', '#22FFCD', '#7737FF'], smooth: true, XYName: { x: '日期', y: '时间' } }">
                            </MyChart5>

                        </div>
                    </div>
                </div>
                <div class="row_2 row_2_size1">
                    <myTitleBox title="每天的题量"></myTitleBox>
                    <div class="info_box">
                        <myBorder2></myBorder2>
                        <div class="info_box_info" style="box-sizing: border-box;">
                            <myBorder></myBorder>
                            <MyChart6 :pageData="{ pageInfo: pageInfo.everyday_ques }"></MyChart6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row_2">
            <myTitleBox title="考试每道题时间曲线"></myTitleBox>
            <div class="info_box">
                <myBorder2></myBorder2>
                <div class="info_box_info" style="box-sizing: border-box;">
                    <myBorder></myBorder>
                    <MyChart2></MyChart2>

                </div>
            </div>
        </div>
        <div class="row_2">
            <myTitleBox title="考试每道难度曲线"></myTitleBox>
            <div class="info_box">
                <myBorder2></myBorder2>
                <div class="info_box_info2"> 
                    <myBorder></myBorder>
                    <MyChart2></MyChart2>
                </div>
            </div>
        </div> -->
            <div class="row_2">
                <myTitleBox title="已学题的错误率图谱"></myTitleBox>
                <div class="info_box">
                    <myBorder2></myBorder2>
                    <div class="info_box_info2">
                        <myBorder></myBorder>
                        <MyChart3 :pageData="{ pageInfo: pageInfo.know_freezing, pageColor: ['#00d8ff', '#8cffe3'] }">>
                        </MyChart3>
                    </div>
                </div>
            </div>
            <div class="charts_end_box">
                <endBox></endBox>
            </div>
        </div>
        <div v-else class="null_hint">当前暂无学习记录</div>
    </div>
</template>
  
<script>

import { _getDateListStudy, _getDateList, _getSubjectList, _getSubExamList, _getOneExam, _getAllExam, _reportSubject, _getStudyInfo } from "../../api/bigScreen.js"
import myBorder from "./components/myBorder.vue"
import myBorder2 from "./components/myBorder2.vue"
import myTitleBox from "./components/myTitleBox.vue"
import MyChart2 from "./components/myCharts2.vue"
import MyChart3 from "./components/myCharts3.vue"
import MyChart4 from "./components/myCharts4.vue"
import MyChart5 from "./components/myCharts5.vue"
import MyChart6 from "./components/myCharts6.vue"
import endBox from "./components/endBox.vue"
import { Loading } from 'element-ui';
export default {
    components: { myBorder, myBorder2, myTitleBox, MyChart2, MyChart3, MyChart4, MyChart5, MyChart6, endBox },
    created() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
        } else {
            this.is1920 = false
        }
        this.getData()
    },

    destroyed() {

    },

    mounted() {

    },
    data() {
        return {
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            is1920: false,

            selectValue: '',
            isDropShow: false,
            dropIndex: 0,
            dropArr: [

            ],
            examTypeInde: 0,
            examType: ["单词考试数据", "整体考试数据"],
            dateArr: [],
            userName: '',
            dateValue: '',
            subID: "",
            pageInfo: {},
            loading: null
        };
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听，避免内存泄露

    },
    methods: {
        async changeDate(val) {
            console.log(val);

            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    sub_id: this.subID,
                    pro_id: window.localStorage.getItem("proID"),
                    day: val,
                    isShowErr: true
                }
                let res3 = await _getStudyInfo(params)
                this.pageInfo = res3.data
                console.log(this.pageInfo.type_right_accuracy);


                this.$nextTick(() => {
                    this.loading.close();
                });
            } catch (error) {
                console.log(error);
                this.$nextTick(() => {
                    this.loading.close();
                });
            }
        },
        disabledDate(date) {
            if (date.getTime() >= Date.now()) {
                return true
            } else {
                let index = this.dateArr.indexOf(this.timestampToDateTime(date.getTime()))
                console.log(index);
                if (index == -1) {
                    return true
                } else {
                    return false
                }
            }
        },
        async getData() {
            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    pro_id: window.localStorage.getItem("proID"),
                    isShowErr: true,
                }
                // 获取日期列表
                let res = await _getDateListStudy(params)
                this.dateArr = this.setDate(res.data[0])//格式化日期 获取数组
                this.userName = res.data[1].stu_name
                this.$emit('send-username', this.userName);
                if (this.dateArr.length <= 0) {
                    this.$nextTick(() => {
                        this.loading.close();
                    });
                    return false
                }

                this.dateValue = this.dateArr[this.dateArr.length - 1]


                let res2 = await _getSubjectList(params)
                this.dropArr = this.setSubjectList(res2.data)
                this.dropArrData = res2.data

                this.selectValue = this.dropArr[0].label
                this.subID = this.dropArr[0].subID

                params = {
                    sub_id: this.subID,
                    pro_id: window.localStorage.getItem("proID"),
                    day: this.dateValue,
                    isShowErr: true
                }
                let res3 = await _getStudyInfo(params)
                this.pageInfo = res3.data
                console.log(this.pageInfo.type_right_accuracy);


                this.$nextTick(() => {
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => {
                    this.loading.close();
                });
            }
        },
        setSubjectList(data) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                tempArr[i] = {
                    value: i + 1,
                    label: data[i].ver.version_name,
                    subID: data[i].sub_id,
                }
            }
            tempArr.unshift({ value: 0, label: "全部科目", subID: 0 })
            return tempArr
        },
        setDate(data) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                tempArr[i] = data[i].days
            }
            return tempArr
        },
        timestampToDateTime(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${year}-${month}-${day}`;
        },
        preventRefresh(event) {

        },
        changeExamType(index) {
            this.examTypeInde = index
            console.log(this.examTypeInde);

        },
        dropShow() {
            this.isDropShow = this.isDropShow == true ? false : true
        },
        selectDrop(index) {
            this.dropIndex = index
        },
        async changeDrop(val) {
            this.subID = this.dropArr[val].subID

            this.loading = Loading.service({
                lock: true,
                text: '数据加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)', fullscreen: true
            });
            try {
                let params = {
                    sub_id: this.subID,
                    pro_id: window.localStorage.getItem("proID"),
                    day: this.dateValue,
                    isShowErr: true
                }
                let res3 = await _getStudyInfo(params)
                this.pageInfo = res3.data
                console.log(this.pageInfo.type_right_accuracy);


                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            } catch (error) {
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    this.loading.close();
                });
            }

        }
    },
};
</script>
<style scoped lang="less" type='text/less'>
.bs_main_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: .25rem .25rem 0 .25rem;
    height: 100%;
    background-color: #071037;
}

.tool_box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 50%;
    top: 1.175rem;
    padding: 0 .25rem .125rem .25rem;
    transform: translate(-50%, 0);
    background-color: #071037;
    z-index: 99;
    box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
    -webkit-box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
    -moz-box-shadow: 0px 8px 4px -4px rgba(140, 253, 255, 0.06);
}

.tool_box .left {
    display: flex;
}

.tool_box .left .btn_item {
    flex-shrink: 0;
    height: .425rem;
    line-height: .425rem;
    padding: 0 .1rem;
    color: #82899e;
    border: 1px solid #0c335a;
    margin-right: .2rem;
    cursor: pointer;
    background-color: #06133b;
}

.btn_item_select {
    color: #1ec1e6 !important;
    border: 1px solid #1ec1e6 !important;
    background-color: #001e4d;
}

.tool_box .right {
    display: flex;
}


.drop_box::-webkit-scrollbar {
    width: .1rem;
    height: .0625rem;
}

.drop_box::-webkit-scrollbar-thumb {
    background: #1ec1e6;
    border-radius: 0;
}

.drop_box::-webkit-scrollbar-track {
    background-color: #001e4d;
    border: 1px solid #001e4d;
}

.drop_item {
    box-sizing: border-box;
    padding: .1rem .2rem;
    white-space: nowrap;
    font-size: .175rem;
    border-bottom: 1px solid #1ec1e6;
}

.drop_item_select {
    color: #1ec1e6;
}

.drop_item:hover {
    background-color: #1ec1e6;
    color: #fff !important;
}

:deep .el-input__inner {
    height: .475rem;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 1px solid #1ec1e6;
    background-color: #001e4d;
    color: #fff;
}

:deep .el-input__inner:hover {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:focus {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:hover {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__inner:focus {
    border: 1px solid #1ec1e6 !important;
}

:deep .el-input__icon {
    line-height: .475rem !important;
}

.row_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: .2rem 0;
}

.row_1_bg {
    padding: 0 !important;
    position: relative;
    height: 2.125rem;
    margin-top: .6rem;
    margin-bottom: .2rem;
    background: linear-gradient(180deg, rgba(2, 71, 190, 0.19) 0%, rgba(1, 33, 88, 0) 100%);
}

.row_1 .study_data_list {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: .2rem .2rem;
}

.row_1 .study_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.row_1 .study_item .text1 {
    font-size: .775rem;
}

.row_1 .study_item .text2 {
    font-size: .25rem;
    margin-top: .125rem;
}

.row_1>div {
    position: relative;
}

.row_1 .title {
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: .25rem;
    padding-left: .325rem;
}

.row_1 .little_box {
    width: 7.6rem;
    height: 5.875rem;

}

.point_box {
    width: 2.125rem;
    height: 2.125rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px dashed rgba(25, 198, 255, 1);
    ;
    margin: .6875rem auto 0;
    position: relative;
}

.point_box .point_box_2 {
    width: 1.925rem;
    height: 1.925rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: .0375rem solid rgba(25, 198, 255, 1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ld_box {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.point_box .ld_box .ld {
    width: 2.4375rem;
    height: 2.4375rem;
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    100% {
        transform-origin: center center;
        transform: rotate(360deg);
    }
}

.point_box .point_text {
    color: rgba(140, 253, 255, 1);
    font-weight: 700;
    font-size: .8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.text_hint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .475rem auto 0;
}

.text_hint .text {
    color: rgba(255, 255, 255, 1);
    font-size: .2rem;
}

.text_hint img {
    width: .4rem;
    height: .4rem;
    margin-left: .15rem;
}

.info_box_info {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.info_box_info2 {
    width: 98%;
    height: 94%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.row_2 {
    width: 100%;
    height: 6.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: .25rem;
}

.row_2_size1 {
    width: 7.6rem;
    height: 5.875rem;
}

.row_2 .info_box {
    width: 100%;
    flex: 1;
    margin-top: .2rem;
    position: relative;
}

.charts_end_box {
    width: 100%;
    height: .35rem;
    margin-top: .2rem;
}

.hint {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: .175rem;
    color: #409eff;
}

.null_hint {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #409eff;
}
</style>
  