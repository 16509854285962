<template>
    <div ref="myChart" id="charts4"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';
// prettier-ignore

// prettier-ignore
// const data = [
//     [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
//     [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
//     [0, 22, 50], [0, 23, 100]]
//     .map(function (item) {
//         return [item[1], item[0], item[2] || '-'];
//     });
export default {
    name: 'MyChart4',
    props: {
        pageData: Object
    },
    data() {
        return {
            is1920: false,
            chartsSize: 100,
            XData: [
                '汽油储运', '金属腐蚀与防护', '管道防腐层', '管道阴极保护', '水工保护', '电工学', '管道施工',
                '安全及环境保护', '管道保卫', '管道内腐蚀控制', '管道干扰与防护', '焊接',
                '管材', '管体缺陷及修复', '管道完整性', '管道应急抢修', '管道工程', '应急抢修设备操作',
                '突发事件前期处置', '电子技术', '操作与维护有关设备、设施', '安全保障', '综合管理', '管道保护和检测设备', '防腐层防腐', '管道缺陷及修复', '履职能力', '法律法规', '标准规范'
            ],
            YData: ['初级', '中级', '高级', '技师'],
            chartsData: [
                [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
                [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
                [0, 22, 50], [0, 23, 100]]
                .map(function (item) {
                    return [item[1], item[0], item[2] || '-'];
                })
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
        // this.initChart();
    },
    // watch: {
    //     pageData(newValue, oldValue) {
    //         // 当myProp的值发生变化时，这个函数会被调用
    //         console.log("newValue");
    //         console.log(newValue);
    //         console.log("oldValue");
    //         console.log(oldValue);
    //         this.initChart()
    //     }
    // },
    watch: {
        immediate: true,
        deep: true,
        pageData: {
            handler: function (val, oldval) {

                if (val.pageInfo) {

                    // this.pageInfoSeven = val.seven
                    // this.pageInfoAll = val.all
                    // this.pageColor = val.pageColor

                    // console.log(this.pageInfo);
                    // console.log(this.pageColor);
                    this.chartsInfo = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        title: {
                            text: ""
                        },
                        grid: {
                            left: '3%',
                            right: '12%',
                            bottom: '3%',
                            containLabel: true
                        },
                        legend: {
                            // data: ['正确题数', '已学题数', '总题数']
                            itemHeight: 12,
                            icon: 'circle',
                            type: 'plain',

                            right: 30,
                            top: 10,
                            bottom: 20,

                            textStyle: {
                                color: "#fff"
                            },
                            data: [
                                {
                                    name: "正确题数",
                                    itemStyle: {
                                        color: '#00D8FF',
                                    }
                                },
                                {
                                    name: "已学题数",
                                    itemStyle: {
                                        color: '#86FCF9',
                                    }

                                },
                                {
                                    name: "总题数",
                                    itemStyle: {
                                        color: '#20284b',
                                    }

                                },
                            ]
                        },

                        xAxis: {
                            type: 'value',
                            name: "题号",
                            nameTextStyle: {
                                color: "#fff"
                            },
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',

                                }
                            },
                            axisTick: {
                                show: true
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#1c2962'
                                }
                            }

                            // data: ["第一季度", "第二季度", "第三季度", "第四季度",]
                        },
                        yAxis: {
                            type: 'category',
                            data: ['单选题', '多选题', '判断题'],
                            name: "题型",
                            nameTextStyle: {
                                color: "#fff"
                            },

                        },
                        series: [
                            {
                                name: val.pageInfo[0].title,
                                type: 'bar',
                                // data: [300, 400, 500],
                                data: val.pageInfo[0].data,
                                barWidth: 24,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#20284b'
                                },
                                label: {
                                    show: true, // 显示文本
                                    position: ['103%', 3], // 文本位置
                                    // 可以通过formatter自定义显示格式
                                    formatter(series) {
                                        console.log(val.pageInfo[series.dataIndex].accuracy+ "%");
                                        return val.pageInfo[series.dataIndex].accuracy+ "%";
                                    },

                                    textStyle: {
                                        fontSize: "13",//文字大小
                                        color: "#00D8FF",//文字颜色
                                    }
                                }
                            },
                            {
                                name: val.pageInfo[1].title,
                                type: 'bar',
                                barGap: '-100%',
                                // data: [151, 152, 153],
                                data: val.pageInfo[1].data,
                                barWidth: 25,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#86FCF9'
                                },
                                label: {
                                    show: false, // 显示文本
                                    position: 'insideRight', // 文本位置
                                    // 可以通过formatter自定义显示格式
                                    formatter(series) {
                                        
                                        console.log(val.pageInfo[series.dataIndex].accuracy+ "%");
                                        return  val.pageInfo[series.dataIndex].accuracy+ "%";
                                    },
                                    color: '#00D8FF'
                                }
                            },
                            {
                                name: val.pageInfo[2].title,
                                type: 'bar',
                                barGap: '-100%',
                                data: val.pageInfo[2].data,
                                barWidth: 25,
                                itemStyle: {
                                    // 设置柱状图的颜d色
                                    color: '#00D8FF'
                                },
                                label: {
                                    show: false, // 显示文本
                                    position: 'insideRight', // 文本位置
                                    // 可以通过formatter自定义显示格式
                                    formatter(series) {
                                        
                                        console.log(val.pageInfo[series.dataIndex].accuracy+ "%");
                                        return  val.pageInfo[series.dataIndex].accuracy+ "%";
                                    },
                                    color: '#86FCF9'
                                }
                            }
                        ]
                    }

                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    methods: {
        initChart(data) {

            var that = this

            const myChart = echarts.init(this.$refs.myChart,);
            myChart.setOption(
                data
                // {
                //     tooltip: {
                //         trigger: 'axis',
                //         axisPointer: {
                //             type: 'shadow'
                //         }
                //     },
                //     title: {
                //         text: ""
                //     },
                //     grid: {
                //         left: '3%',
                //         right: '12%',
                //         bottom: '3%',
                //         containLabel: true
                //     },
                //     legend: {
                //         // data: ['正确题数', '已学题数', '总题数']
                //         itemHeight: 12,
                //         icon: 'circle',
                //         type: 'plain',

                //         right: 30,
                //         top: 10,
                //         bottom: 20,

                //         textStyle: {
                //             color: "#fff"
                //         },
                //         data: [
                //             {
                //                 name: "正确题数",
                //                 itemStyle: {
                //                     color: '#00D8FF',
                //                 }
                //             },
                //             {
                //                 name: "已学题数",
                //                 itemStyle: {
                //                     color: '#86FCF9',
                //                 }

                //             },
                //             {
                //                 name: "总题数",
                //                 itemStyle: {
                //                     color: '#20284b',
                //                 }

                //             },
                //         ]
                //     },

                //     xAxis: {
                //         type: 'value',
                //         name: "题号",
                //         nameTextStyle: {
                //             color: "#fff"
                //         },
                //         axisLine: {
                //             lineStyle: {
                //                 type: 'solid',

                //             }
                //         },
                //         axisTick: {
                //             show: true
                //         },
                //         splitLine: {
                //             show: true,
                //             lineStyle: {
                //                 type: 'dashed',
                //                 color: '#1c2962'
                //             }
                //         }

                //         // data: ["第一季度", "第二季度", "第三季度", "第四季度",]
                //     },
                //     yAxis: {
                //         type: 'category',
                //         data: ['单选题', '多选题', '判断题'],
                //         name: "题型",
                //         nameTextStyle: {
                //             color: "#fff"
                //         },

                //     },
                //     series: [
                //         {
                //             name: '总题数',
                //             type: 'bar',
                //             data: [300, 400, 500],
                //             barWidth: 24,
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#20284b'
                //             },
                //             label: {
                //                 show: true, // 显示文本
                //                 position: ['103%', 3], // 文本位置
                //                 // 可以通过formatter自定义显示格式
                //                 formatter: `正确率:\n10%`, // {c}表示数据值
                //                 textStyle: {
                //                     fontSize: "13",//文字大小
                //                     color: "#00D8FF",//文字颜色
                //                 }

                //             }
                //         },
                //         {
                //             name: '已学题数',
                //             type: 'bar',
                //             barGap: '-100%',
                //             data: [151, 152, 153],
                //             barWidth: 25,
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#86FCF9'
                //             },
                //             label: {
                //                 show: true, // 显示文本
                //                 position: 'insideRight', // 文本位置
                //                 // 可以通过formatter自定义显示格式
                //                 formatter: '{c}', // {c}表示数据值
                //                 color: '#00D8FF'
                //             }
                //         },
                //         {
                //             name: '正确题数',
                //             type: 'bar',
                //             barGap: '-100%',
                //             data: [101, 102, 103],
                //             barWidth: 25,
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#00D8FF'
                //             },
                //             label: {
                //                 show: true, // 显示文本
                //                 position: 'insideRight', // 文本位置
                //                 // 可以通过formatter自定义显示格式
                //                 formatter: '{c}', // {c}表示数据值
                //                 color: '#86FCF9'
                //             }
                //         }
                //     ]
                // }

            );

            this.$nextTick(() => {
                console.log("myChart.resize()");
                myChart.resize()
            })
        }
    }
};
</script>
<style></style>

