<template>
  <div style="height: 100%;">
    <div class="result_box" :class="[is1920 == true ? 'data_detil_box_1920' : 'result_box_1366']">
      <div class="left">
        <div class="press_box">
          <el-progress type="dashboard" :percentage="percentage" color="#695fee" :stroke-width="14" :show-text="false"
            define-back-color="#cdd8fd"></el-progress>
          <div class="info_box">
            <div class="point">{{ pageInfo.correct_rate }}%</div>
            <div class="text">正确率</div>
          </div>
        </div>
        <div class="date">提交时间：{{ pageInfo.submit_time }}</div>
        <div class="exam_info_box">
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.ques_sum }}</div>
            <div class="text2">一共</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.study_count }}</div>
            <div class="text2">已答题</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.ont_num }}</div>
            <div class="text2">未答题</div>
          </div>
          <div class="exam_info_item">
            <div class="text1">{{ pageInfo.take_time }}</div>
            <div class="text2">用时</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title_box">
          <div class="text">数据对比</div>
          <div class="line"></div>
        </div>
        <div class="data_box">
          <div class="hint_box">
            <div class="hint_item">
              <div class="color_line color_1"></div>
              <div class="text">练题模式</div>
            </div>
            <div class="hint_item">
              <div class="color_line color_2"></div>
              <div class="text">背题模式</div>
            </div>
            <div class="hint_item">
              <div class="color_line color_3"></div>
              <div class="text">测题模式</div>
            </div>
          </div>
          <div class="data_detail_box" :class="[is1920 == true ? 'data_detail_box_1920' : '']" v-if="pageInfo">
            <div class="data_detail_item" :class="[is1920 == true ? 'data_detail_item_1920' : '']">
              <div class="left">
                <div>正确率</div>
                <div>（%）</div>
              </div>
              <div class="right" >
                <div class="line_wai">
                  <div v-if="pageInfo.accuracy" class="line_nei color_1"
                    :style="{ width: pageInfo.accuracy.train_rate + '%' }"></div>
                  <div class="text" v-if="pageInfo.accuracy">{{ pageInfo.accuracy.train_rate }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.accuracy" class="line_nei color_3"
                    :style="{ width: pageInfo.accuracy.infer_rate + '%' }"></div>
                  <div class="text" v-if="pageInfo.accuracy">{{ pageInfo.accuracy.infer_rate }}</div>
                </div>
              </div>
            </div>
            <div class="data_detail_item" :class="[is1920 == true ? 'data_detail_item_1920' : '']">
              <div class="left">
                <div>练习时长</div>
                <div>（分）</div>
              </div>
              <div class="right">
                <div class="line_wai">
                  <div v-if="pageInfo.mark" class="line_nei color_1"
                    :style="{ width: pageInfo.mark.train_mark_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.mark">{{ pageInfo.mark.train_mark }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.mark" class="line_nei color_2"
                    :style="{ width: pageInfo.mark.back_mark_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.mark">{{ pageInfo.mark.back_mark }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.mark" class="line_nei color_3"
                    :style="{ width: pageInfo.mark.infer_mark_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.mark">{{ pageInfo.mark.infer_mark }}</div>
                </div>
              </div>
            </div>
            <div class="data_detail_item" :class="[is1920 == true ? 'data_detail_item_1920' : '']">
              <div class="left">
                <div>答题量</div>
                <div>（道）</div>
              </div>
              <div class="right">
                <div class="line_wai">
                  <div v-if="pageInfo.study" class="line_nei color_1"
                    :style="{ width: pageInfo.study.train_num_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.study">{{ pageInfo.study.train_num }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.study" class="line_nei color_2"
                    :style="{ width: pageInfo.study.back_num_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.study">{{ pageInfo.study.back_num }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.study" class="line_nei color_3"
                    :style="{ width: pageInfo.study.infer_num_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.study">{{ pageInfo.study.infer_num }}</div>
                </div>
              </div>
            </div>
            <div class="data_detail_item" :class="[is1920 == true ? 'data_detail_item_1920' : '']">
              <div class="left">
                <div>总进度</div>
                <div>（道）</div>
                <div v-if="pageInfo.sum">共{{pageInfo.sum.ques_sum}}</div>
              </div>
              <div class="right">
                <div class="line_wai">
                  <div v-if="pageInfo.sum" class="line_nei color_1"
                    :style="{ width: pageInfo.sum.train_sum_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.sum">{{ pageInfo.sum.train_sum }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.sum" class="line_nei color_2"
                    :style="{ width: pageInfo.sum.back_sum_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.sum">{{ pageInfo.sum.back_sum }}</div>
                </div>
                <div class="line_wai">
                  <div v-if="pageInfo.sum" class="line_nei color_3"
                    :style="{ width: pageInfo.sum.infer_sum_width + '%' }"></div>
                  <div class="text" v-if="pageInfo.sum">{{ pageInfo.sum.infer_sum }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_box">
          <div class="btn_item btn_1" @click="back()">返回科目</div>
          <div class="btn_item btn_2" @click="loogWrong()">查看错题</div>
          <div class="btn_item btn_2" @click="studyAgain()">再练一组</div>
        </div>
      </div>
      <div class="line_box">
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
        <div class="line">
          <div class="yuan yuan1"></div>
          <div class="fang"></div>
          <div class="yuan yuan2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _dklxResult } from "../../api/resultPage"
import * as exerciseApi from '@/api/exercise.js';
import * as global from '@/mixins/global.js';

export default {
  components: {},
  beforeCreate() { },
  created() {
    if (parseInt(window.screen.width) >= 1920) {
      this.is1920 = true
    } else {
      this.is1920 = false
    }
    console.log(this.$route.query);
    if (this.$route.query) {
      this.reqInfo = this.$route.query
    }

    this.examResult()
  },

  mounted() { },
  data() {
    return {
      widthPercent: 50,
      percentage: 0,
      pageInfo: {},
      is1920: false,
      reqInfo: {}
    };
  },
  methods: {
    async examResult() {

      console.log(this.reqInfo);
      let res = await _dklxResult(this.reqInfo)
      // this.setData(res.data)
      // console.log(res);

      this.pageInfo = this.setData(res.data)
      
      // this.percentage = this.GetPercent(this.pageInfo.already_answer, this.pageInfo.ques_all)
    },
    setData(data) {
      console.log(data);
      
      data.correct_rate = parseInt(data.correct_rate)
      // data.mark.back_mark = parseInt(data.mark.back_mark)
      // data.mark.train_mark = parseInt(data.mark.train_mark)
      // data.mark.infer_mark = parseInt(data.mark.infer_mark)

      let tempArr = [data.mark.back_mark, data.mark.train_mark, data.mark.infer_mark]
      let max = Math.max(...tempArr);

      data.mark.back_mark_width= this.GetPercent(data.mark.back_mark,max)
      data.mark.train_mark_width= this.GetPercent(data.mark.train_mark,max)
      data.mark.infer_mark_width= this.GetPercent(data.mark.infer_mark,max)



      tempArr = [data.study.back_num, data.study.train_num, data.study.infer_num]
      max = Math.max(...tempArr);

      data.study.back_num_width= this.GetPercent(data.study.back_num,max)
      data.study.train_num_width= this.GetPercent(data.study.train_num,max)
      data.study.infer_num_width= this.GetPercent(data.study.infer_num,max)


      data.sum.back_sum_width= this.GetPercent(data.sum.back_sum,data.sum.ques_sum)
      data.sum.train_sum_width= this.GetPercent(data.sum.train_sum,data.sum.ques_sum)
      data.sum.infer_sum_width= this.GetPercent(data.sum.infer_sum,data.sum.ques_sum)

      return data
    },

    pageBack() {
      this.$router.push({ path: "/Index/TYKSList" });
    },
    GetPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0 ? "0" : (Math.round(num / total * 10000) / 100.00);
    },
    back(){
      this.$router.replace({name: "DKLXList"});
    },
    loogWrong(){
      this.$router.replace({path: "/Index/DKLXLookWrong",query:{study_id:this.reqInfo.study_id}});
    },
    // 在练一组！
    async studyAgain(){
      //科目详情
      const subjectInfoRes = await exerciseApi.subjectInfo({
          pro_id:localStorage.getItem('proID'),
        });
      const serical = this.$route.query.study_type == global.StudyType.QuanTiKu ? subjectInfoRes.data.reply_serical : undefined;
      this.$router.replace({
        name: "DKLXPage",
        query: {
          ...this.$route.query,
          ques_sum:undefined,
          study_id:undefined,
          serical,
        }
      });
    },

  },


};
</script>
<style scoped>
.result_box {
  width: 58%;
  height: 63%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result_box_1366 {
  width: 65% !important;
  height: 70% !important;
}

.result_box>div {
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  border-radius: 40px;
}

.result_box>.left {
  margin-right: 10px;
  background: linear-gradient(162.49deg, rgba(155, 178, 250, 1) 5.03%, rgba(211, 222, 255, 0.37) 111.82%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

}

.result_box>.right {
  margin-left: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.press_box {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 36px;
}

.info_box {
  width: 40%;
  height: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 10px;
}

.info_box .point {
  color: rgba(100, 110, 255, 1);
  font-weight: 700;
  font-size: 30px;
}

.info_box .text {
  color: rgba(114, 123, 142, 1);
  font-size: 14px;
}

.exam_info_box {
  width: 90%;
  height: 108px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px 10px 30px;
}

.exam_info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam_info_item .text1 {
  color: rgba(29, 37, 54, 1);
  font-size: 28px;
}

.exam_info_item .text2 {
  color: rgba(114, 123, 142, 1);
  font-size: 12px;
  margin-top: 14px;
}

.title_box {
  position: relative;
}

.title_box .text {
  color: rgba(0, 0, 0, 1);
  font-size: .25rem;
  position: relative;
  z-index: 2;
}

.title_box .line {
  width: 112%;
  height: 17px;
  border-radius: 29px;
  background: rgba(155, 178, 250, 1);
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translate(-50%, 0);
}

.date {
  color: #fff;
  font-size: 16px;
  margin-bottom: .575rem;
}

.data_box {
  width: 100%;
}

.hint_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;
}

.hint_item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.hint_item .color_line {
  width: 12px;
  height: 4px;
  margin-right: 10px;
  border-radius: 4px;
}

.color_1 {
  background: linear-gradient(90deg, #ffffff00 0%, #3c9cff 100%);
}

.color_2 {
  background: linear-gradient(90deg, #ffffff00 0%, #ffae52 100%);
}

.color_3 {
  background: linear-gradient(90deg, #ffffff00 0%, #6666ff 100%);
}

.hint_item .text {
  color: #727b8e;
  font-size: 12px;
}

.data_detail_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data_detail_box_1920 {
  margin-bottom: 30px;
}

.data_detail_item {
  width: 70%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #727b8e;
  background-color: #dee5fd;
  border-radius: 10px;
  margin-bottom: 12px;
}

.data_detail_item_1920 {
  padding: 11px 0 !important;

}

.data_detail_item .left {
  width: 20%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.data_detail_item .right {
  flex: 1;
  box-sizing: border-box;
  padding: 0 10px 0 0;
}

.line_wai {
  width: 100%;
  height: 5px;
  box-sizing: border-box;
  padding-right: 30px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
}

.line_wai .text {
  color: #727b8e;
  font-weight: 300;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.line_nei {
  height: 5px;
  border-radius: 5px;
}

.line_box {
  width: 110px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
}

.line_box .line {
  width: 100%;
  height: 20px;
  position: relative;
}

.line_box .line .yuan {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgba(155, 178, 250, 1);
  position: absolute;
}

.line_box .line .yuan1 {

  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.line_box .line .yuan2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.line_box .line .fang {
  width: 102px;
  height: 13px;
  border-radius: 21px;
  background: rgba(100, 110, 255, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.btn_box {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

}

.btn_item {
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn_1 {
  color: #000;
  box-sizing: border-box;
  border: 1px solid #7a98f9;

}

.btn_2 {
  color: #fff;
  box-sizing: border-box;
  border: 1px solid #646eff;
  background-color: #646eff;
}
</style>
