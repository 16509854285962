<template>
  <div class="my_border_box">
    <div class="my_border_box_line_1 heng"></div>
    <div class="my_border_box_line_2 heng"></div>
    <div class="my_border_box_line_3 heng"></div>
    <div class="my_border_box_line_4 heng"></div>
    <div class="my_border_box_line_5 shu"></div>
    <div class="my_border_box_line_6 shu"></div>
    <div class="my_border_box_line_7 shu"></div>
    <div class="my_border_box_line_8 shu"></div>
  </div>
</template>
  

<script>

export default {
  name: "myBorder",
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style >
.my_border_box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.heng {
  width: .1625rem;
  height: .025rem;
  background-color: rgba(25, 195, 226, 1);
  position: absolute;

}

.shu {
  width: .025rem;
  height: .1625rem;
  background-color: rgba(25, 195, 226, 1);
  position: absolute;
}

.my_border_box_line_1 {
  left: 0;
  top: 0; 
}

.my_border_box_line_2 {
  right: 0;
  top: 0;
  
}

.my_border_box_line_3 {
  bottom: 0;
  left: 0;
}

.my_border_box_line_4 {
  bottom: 0;
  right: 0;
}
s
.my_border_box_line_5 {
  left: 0;
  top: 0;
}

.my_border_box_line_6 {
  right: 0;
  top: 0;
}

.my_border_box_line_7 {
  bottom: 0;
  left: 0;
}

.my_border_box_line_8 {
  bottom: 0;
  right: 0;
}
</style>

