//功能：暴露接口
import {
	baseURL
} from '../config/index.js'

import * as global from '@/mixins/global.js';

import axios from 'axios'
import {
	Message
} from 'element-ui'

import { Loading } from 'element-ui';
var loadingInstance = null;

window.RequestHttpException = 'RequestHttpException';
window.RequestApiException = 'RequestApiException';

class RequestException {
	error = null;
	type = null;
	constructor(type, error) {
		this.type = type;
		this.error = error;
	}
}

function excludeFields(jsonObj, fieldsToExclude) {
	return Object.keys(jsonObj)
		.filter(key => !fieldsToExclude.includes(key))
		.reduce((obj, key) => {
			obj[key] = jsonObj[key];
			return obj;
		}, {});
}
const fieldsToExclude = ['load', 'isShowMsg', 'isShowErr'];
export const request = (options) => { //暴露一个function：request，使用options接收页面传过来的参数
	var token = window.localStorage.getItem("userToken")
	let params = excludeFields(options.data, fieldsToExclude);
	// 请求头信息
	options.headers = {
		"token": token,
    'ver': process.env.APP_VERSION.ver,
    'vercode': process.env.APP_VERSION.vercode,
	};
	if (options.isLoading) {
		loadingInstance = Loading.service({ text: "加载中", background: "rgba(0,0,0,.3)" });
	}
	return new Promise((resolve, reject) => { //异步封装接口，使用Promise处理异步请求
		axios({ //发送请求
			url: baseURL + options.url, //接收请求的API
			method: options.method || 'get', //接收请求的方式,如果不传默认为GET
			data: params || {}, //接收请求的data,不传默认为空
			headers: options.headers || {},
		}).then((res) => {
			if (loadingInstance) { loadingInstance.close(); }
      //tocken过期，重置为刷新token
      if (res.headers?.refresh_token) {
        window.localStorage.setItem("userToken", res.headers.refresh_token);
      }
      if (res.status == 200) {
        if (res.data.code == -1) {
          if (options.isShowErr) {
          	Message({ showClose: true, message: res.data.msg, type: "error", duration: 2500 });
          }
          reject(new RequestException(window.RequestApiException, res.data));
        } else {
          if (options.isShowMsg) {
          	Message({ showClose: true, message: res.data.msg, type: "success", duration: 2500 });
          }
          resolve(res.data);
        }
      }
		}).catch(err => {
      let messageShowed = false;
      const status = err.response?.status;
      const code = err.response?.data?.code;
      if (status == 401) {
        global.logout();
        const message = (code == -4011 || code == -401) ? err.response.data.msg : '请重新登录账号';
        Message({ showClose: true, message: message, type: "error", duration: 2500 });
        messageShowed = true;
      }
      if (!messageShowed && options.isShowErr) {
        Message({ showClose: true, message: (status ? status + ' ' : '') + "网络异常，请联系管理员。", type: "error", duration: 2500 });
      }
			if (loadingInstance) { loadingInstance.close(); }
			reject(new RequestException(window.RequestHttpException, err));
		})
	})
}

