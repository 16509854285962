<template>
  <div>
    <img class="page_bg" src="../img/login_bg.png" alt />

    <img class="logo_box" src="../img/logo.png" alt="">

    <div class="login_box">
      <div class="title_text">用户登录</div>

      <div class="input_box">
        <div class="title">账号</div>
        <el-input v-model="input1" placeholder="请输入账号"></el-input>
      </div>
      <div class="input_box">
        <div class="title">密码</div>
        <el-input v-model="input2" placeholder="请输入密码" show-password></el-input>
      </div>
      <div class="login_btn" @click="jumpIndex" v-throttle>登录</div>
    </div>
    <div class="version">{{version}}</div>
  </div>
</template>

<script>
import { _login } from "../api/login"
import * as global from '@/mixins/global.js';

export default {
  components: {},
  created() {
    window.sessionStorage.setItem("tabIndex", null);

    console.log('build time:', process.env.APP_VERSION.ver)
    this.version = "版本：v." +process.env.APP_VERSION.ver

    global.exitFullScreen();
  },

  mounted() { },
  data() {
    return {
      input1: "",
      input2: "",
      version:""
    };
  },
  methods: {
    async jumpIndex() {
      let reqData = {
        account: this.input1,
        password: this.input2,
        isShowErr: true,
        load: true
      }
      let res = await _login(reqData)
      this.$message({
        showClose: true, message: "登录成功", type: "success", duration: 1200, onClose: () => {
          window.localStorage.clear()
          window.localStorage.setItem("userToken", res.data.token)
          this.$nextTick(() => {

            this.$router.replace({ path: "/Index" });
          })
        }
      });
    },
  },
};
</script>
<style scoped>
page {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.page_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.login_box {
  position: fixed;
  top: 50%;
  right: 13%;
  transform: translate(0, -50%);
  width: 380px;
  height: 500px;
  border-radius: 20px;
  border-width: 4px;
  border-style: solid;
  border-image-source: conic-gradient(from 166.73deg at 51.4% 52.96%,
      rgba(0, 136, 255, 0.78) 12.08deg,
      rgba(124, 194, 255, 0.14) 44.19deg,
      rgba(0, 136, 255, 0.78) 110.71deg,
      rgba(150, 206, 255, 0) 137.32deg,
      rgba(0, 136, 255, 0.78) 182.22deg,
      rgba(150, 206, 255, 0) 193.76deg,
      rgba(0, 136, 255, 0.78) 208.83deg,
      rgba(150, 206, 255, 0) 217.54deg,
      rgba(0, 136, 255, 0.78) 262.37deg,
      rgba(0, 10, 255, 0.71) 279.32deg,
      rgba(0, 136, 255, 0.78) 301.73deg,
      rgba(0, 10, 255, 0.71) 331.61deg,
      rgba(124, 194, 255, 0.14) 349.43deg);
  border-image-slice: 1;
  box-sizing: border-box;
  background: linear-gradient(113.92deg,
      rgba(255, 255, 255, 1) 1.94%,
      rgba(255, 255, 255, 0) 105.78%);
  backdrop-filter: blur(30px);
}

.login_box .title_text {
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 46px;
}

.input_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.input_box .title {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
}

:deep(.el-input__inner) {
  border: none;
  border-radius: 0;
  color: #000;
}

.login_btn {
  width: 252px;
  height: 40px;
  display: flex;
  place-content: center;
  place-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  color: #fff;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer;
}

.logo_box {
  width: 410px;
  height: 156px;
  position: fixed;
  left: 13%;
  top: 15%;
}
.version {
  position: fixed;
  left: 10px;
  bottom: 10px;
  font-size:13px;
  color: rgb(128, 128, 128,0.8);
}
</style>

