import { request } from "../utils/request"


export function _login(data) {
    return request({
        url: 'stuwebapi/passport/login',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}

