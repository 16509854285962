import { render, staticRenderFns } from "./comfirm.vue?vue&type=template&id=5541d335&scoped=true"
import script from "./comfirm.vue?vue&type=script&lang=js"
export * from "./comfirm.vue?vue&type=script&lang=js"
import style0 from "./comfirm.vue?vue&type=style&index=0&id=5541d335&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5541d335",
  null
  
)

export default component.exports