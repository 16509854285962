<template>
  <div class="null_box">
    <img src="../img/null_pic.png" alt="">
    <div class="null_box_text">暂无数据</div>
  </div>
</template>
  

<script>

export default {
  name:"nullBox",
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style scoped>
.null_box {
  position: fixed;
  left: 50%;
  top: 42%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.null_box img {
  width: 324px;
  height: 240px;
}
.null_box .null_box_text {
  color: rgba(155, 178, 250, 1);
  font-size: 16px;
  margin-top: 20px;
}
</style>

