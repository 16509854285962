import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Index from '../views/Index.vue'
import IndexPage from '../views/indexPage.vue'
import ClassName from '../views/className.vue'
import ExamTab from '../views/examTab.vue'
import TYKSList from '../views/examTabTYKS/TYKSList.vue'
import TYKSStart from '../views/examTabTYKS/TYKSStart.vue'
import TYKSPage from '../views/examTabTYKS/TYKSPage.vue'
import ResultPage from '../views/resultPage.vue'
import DKLXList from '../views/examTabDKLX/DKLXList.vue'
import DKLXPage from '../views/examTabDKLX/DKLXPage.vue'
import DKLXResultPage from '../views/examTabDKLX/DKLXResultPage.vue'
import DKLXLookWrong from '../views/examTabDKLX/DKLXLookWrong.vue'

import BigScreen from '../views/bigScreen/bigScreen.vue'
import BigScreenExam from '../views/bigScreen/bigScreenExam.vue'
import BigScreenStudy from '../views/bigScreen/bigScreenStudy.vue'
import LookList from '../views/lookSubject/lookList.vue'
import LookAll from '../views/lookSubject/lookAll.vue'
import LookWrong from '../views/lookSubject/lookWrong.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Login',
  component: Login
},
{
  path: '/Index',
  name: 'Index',
  component: Index,
  redirect: '/Index/ClassName',
  children: [
    {
      path: '/Index/IndexPage',
      name: 'IndexPage',
      component: IndexPage,
    },
    {
      path: '/Index/ClassName',
      name: 'ClassName',
      component: ClassName,
    },
    {
      path: '/Index/ExamTab',
      name: 'ExamTab',
      component: ExamTab,
    },
    {
      path: '/Index/TYKSList',
      name: 'TYKSList',
      component: TYKSList,
    },
    {
      path: '/Index/TYKSStart',
      name: 'TYKSStart',
      component: TYKSStart,
    },
    {
      path: '/Index/TYKSPage',
      name: 'TYKSPage',
      component: TYKSPage
    },
    {
      path: '/Index/ResultPage',
      name: 'ResultPage',
      component: ResultPage
    },
    {
      path: '/Index/DKLXList',
      name: 'DKLXList',
      component: DKLXList
    },
    {
      path: '/Index/DKLXPage',
      name: 'DKLXPage',
      component: DKLXPage
    },
    {
      path: '/Index/DKLXResultPage',
      name: 'DKLXResultPage',
      component: DKLXResultPage
    },
    {
      path: '/Index/DKLXLookWrong',
      name: 'DKLXLookWrong',
      component: DKLXLookWrong
    },

    {
      path: '/Index/LookList',
      name: 'LookList',
      component: LookList
    },
    {
      path: '/Index/LookAll',
      name: 'LookAll',
      component: LookAll
    },    {
      path: '/Index/LookWrong',
      name: 'LookWrong',
      component: LookWrong
    },
    {
      path: '/Index/BigScreen',
      name: 'BigScreen',
      component: BigScreen,
      // redirect: '/Index/BigScreen/BigScreenExam',
      children: [
        {
          path: '/Index/BigScreen/BigScreenExam',
          name: 'BigScreenExam',
          component: BigScreenExam,

        },
        {
          path: '/Index/BigScreen/BigScreenStudy',
          name: 'BigScreenStudy',
          component: BigScreenStudy,
        }
      ]

    }
  ]
}]

const router = new VueRouter({
  mode: 'hash',
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }
})


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


router.beforeEach((to, from, next) => {
  // console.log(from);
  // console.log(to);
  // debugger
  if (to.name == 'Login' && !window.localStorage.getItem("userToken"))
    return next();

  if (from.name == "ResultPage" && (to.name == 'LookWrong' || to.name == 'LookAll' || to.name == 'ExamTab'))
    return next();

  if (from.name == "TYKSPage" && to.name != "ResultPage") {
    return next(false)
  } else if (to.name == "TYKSPage") {
    if (window.sessionStorage.getItem("isCanJumpExam") == "true") {
      return next()
    } else {
      return next(false)
    }
  } else if (from.name == "ResultPage" && to.name != "ClassName" && to.name != "TYKSList" && to.name != "Login") {
    return next(false)
  } else if (to.name == "TYKSPage") {
    if (window.sessionStorage.getItem("isCanJumpExam") == "true") {
      return next()
    } else {
      return next(false)
    }
  } else if (to.name == "Login") {
    if (window.localStorage.getItem("userToken")) {
      return next({ name: "ClassName" })
    } else {
      next()
    }
  } else if (to.name != "Login") {
    if (!window.localStorage.getItem("userToken")) {
      return next({ name: "Login" })
    } else {
       next()
    }
  } else {
    next()
  }
  // next()
});
export default router

