import { request } from "../utils/request"


export function _getClassList(data) {
    return request({
        url: 'stuwebapi/train.project/proList',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg:data.isShowMsg,
        isShowErr:data.isShowErr
    })
}
export function _setClassDefault(data) {
    return request({
        url: 'stuwebapi/train.project/default',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}


