<template>
  <div class="my_border_box_2">
    <div class="my_border_box_3">
      <div class="my_border_box_4"></div>
    </div>
  </div>
</template>
  

<script>

export default {
  name: "myBorder2",
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style >
.my_border_box_2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid rgba(140, 253, 255, .4);
}

.my_border_box_3 {
  width: 100%;
  height: 100%;
  position: absolute;
  right: -6px;
  top: -6px;
  border: 1px solid rgba(140, 253, 255, 1);
}

.my_border_box_4 {
  width: 45%;
  height: 3px;
  clip-path: polygon(0% 0%, 100% 0%, 91% 100%, 10% 100%);
  background: rgba(140, 253, 255, 1);
  position: absolute;
  bottom: -4px;
  right: 45px;
}
</style>

