<template>
  <div class="charts_endBox">
    <img src="../../../img/end_pic.png" alt="">
  </div>
</template>
  

<script>

export default {
  name: "endBox",
  components: {},
  created() {

  },
  props: {
    
  },
  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style >
.charts_endBox {
  width: 100%;
  height: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charts_endBox img{
  width: 10.3125rem;
  height: .35rem;
}

</style>

