<template>
  <div v-cloak>
    <div class="page_box">
      <div class="title_box">
        <div class="title">全部班级</div>
        <div class="search_box">
          <el-input size="small" placeholder="请输入班级名称" prefix-icon="el-icon-search" v-model="searchInput" clearable
            @input="inputChage">
          </el-input>
          <div class="saarch_btn" @click="searchInfo">搜索</div>
        </div>
      </div>
      <div class="class_box" @scroll="handleScroll" v-if="classInfo.length > 0">
        <div class="class_item" v-for="(item, index) in classInfo" :key="index">
          <div class="row_1">
            <div class="title">{{ item.pro_name }}</div>
            <div class="type">{{ item.class_type.name }}</div>
          </div>
          <div class="row_2">
            <div class="base_info">{{ item.course_time.name }}—{{ item.finish_time.name }}</div>
            <div class="base_info">科目数量:{{ item.sub_num }}</div>
            <div class="base_info">今日考试数量:{{ item.grant_num }}</div>
            <div class="base_info">负责人:{{ item.charge_name }}</div>
          </div>
          <div class="jump_btn" @click="jumpExamTab(index)" v-throttle>进入</div>
        </div>
      </div>
      <div v-else>
        <nullBox></nullBox>
      </div>
    </div>

    <div class="page_end_fixed">
      <endBox></endBox>
    </div>
  </div>
</template>

<script>
import { _getClassList, _setClassDefault } from "../api/className"
export default {

  created() {
    this.getClassList()
  },

  mounted() { },
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
      searchInput: "",
      infoCount: null,
      classInfo: [],
    };
  },
  methods: {
    inputChage() {
      if (this.searchInput == "" || this.searchInput == null || this.searchInput == undefined) {
        this.pageNumber = 1
        this.getClassList()
      }
    },
    handleScroll(event) {
      const dialog = event.target;
      if (dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight) {
        console.log("滚动到底了");
        if (this.classInfo.length >= this.infoCount) {

          console.log("数据没了");
          return false
        }
        this.pageNumber = this.pageNumber + 1
        this.getClassList()
      }

    },
    searchInfo() {
      this.pageNumber = 1
      this.getClassList()
    },
    async getClassList() {
      let params = {
        listRows: this.pageSize,
        page: this.pageNumber,
        pro_name: this.searchInput
      }
      let res = await _getClassList(params)
      console.log(res.data);
      if (this.pageNumber == 1) {
        this.classInfo = res.data.data
        this.infoCount = res.data.total
      } else {
        this.classInfo = this.classInfo.concat(res.data.data)
        this.infoCount = res.data.total
      }
      console.log(this.classInfo);
    },
    async jumpExamTab(index) {
      let params = {
        pro_id: this.classInfo[index].id,
        isShowErr:true
      }
      await _setClassDefault(params)
      window.localStorage.setItem("proID", this.classInfo[index].id)
      this.$router.push({ name: "ExamTab", params: { className: this.classInfo[index].pro_name } });

    },
  },
};
</script>
<style scoped>
.page_box {
  width: 1200px;
  margin: auto;
}

.title_box {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 16px;
}

.title_box .title {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 18px;
  margin-left: 15px;
}

.class_box {
  width: 100%;
  height: 65vh;
  box-sizing: border-box;
  padding-bottom: 20px;

  margin-top: 16px;
  overflow-y: auto;
}

.class_item {
  width: 100%;
  height: 149px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;
}

.class_item .row_1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class_item .row_1 .title {
  color: rgba(22, 27, 38, 1);
  font-weight: 700;
  font-size: 16px;
}

.class_item .row_1 .type {
  padding: 2px 8px;
  background: rgba(205, 216, 253, 1);
  color: rgba(100, 110, 255, 1);
  font-size: 12px;
}

.class_item .row_2 {
  display: flex;
  color: rgba(144, 147, 153, 1);
  font-size: 16px;
  margin-top: 17px;
}

.class_item .row_2 .base_info {
  margin-right: 30px;
}

.class_item .jump_btn {
  width: 120px;
  height: 36px;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}

.search_box {
  display: flex;
  align-items: center;
}

.el-input__inner {
  height: 60px;
}

.saarch_btn {
  width: 80px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  background: rgba(100, 110, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
