<template>
    <div ref="myChart" id="charts4"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';
// prettier-ignore

// prettier-ignore
// const data = [
//     [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
//     [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
//     [0, 22, 50], [0, 23, 100]]
//     .map(function (item) {
//         return [item[1], item[0], item[2] || '-'];
//     });
export default {
    name: 'MyChart4',
    props: {
        pageData: Object
    },
    data() {
        return {
            is1920: false,
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
        // this.initChart();

    },
    watch: {
        immediate: true,
        deep: true,
        pageData: {
            handler: function (val, oldval) {

                if (val.pageInfo) {


                    // this.pageInfoSeven = val.seven
                    // this.pageInfoAll = val.all
                    // this.pageColor = val.pageColor

                    // console.log(this.pageInfo);
                    // console.log(this.pageColor);
                    this.chartsInfo = {

                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: function (params) {
                                // params 是一个数组，数组中包含每个系列的数据信息
                                // debugger
                                let result = '';
                                // debugger
                                // result = `${params[0].seriesName}: ${params[0].value}<br/>` +
                                //     `${params[2].seriesName}: ${params[3].value}<br/>` +
                                //     `${params[1].seriesName}: ${params[1].value}<br/>` +
                                //     `${params[3].seriesName}: ${params[3].value}<br/>`;

                                result = `<div>` +
                                    `<div calss="hover_title">${params[0].axisValue} : </div>` +
                                    `<div class="hover_box"><div class="yuan color_1"></div><div>${params[2].seriesName}: ${params[2].value}</div></div>` +
                                    `<div class="hover_box"><div class="yuan color_2"></div><div>${params[0].seriesName}: ${params[0].value}</div></div>` +
                                    `<div class="hover_box"><div class="yuan color_3"></div><div>${params[1].seriesName}: ${params[1].value}</div></div>` +
                                    `<div class="hover_box"><div class="yuan color_4"></div><div>${params[3].seriesName}: ${params[3].value}</div></div>` +
                                    `</div>`
                                return result;
                            }
                        },
                        title: {

                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        legend: {
                            // selectedMode: false,
                            right: 30,
                            top: 10,
                            bottom: 20,
                            textStyle: {
                                color: "#fff",
                            },
                            data: [
                                {
                                    icon: 'circle',
                                    name: "个人答题题量",
                                    itemStyle: {
                                        color: '#ACC7FF',
                                    }
                                },
                                {
                                    icon: 'circle',
                                    name: "个人正确的题",
                                    itemStyle: {
                                        color: '#6690FF',
                                    }

                                },
                                {
                                    icon: 'circle',
                                    name: "全员平均答题题量",
                                    itemStyle: {
                                        color: '#D6FEF7',
                                    }

                                },
                                {
                                    icon: 'circle',
                                    name: "全员平均正确的题",
                                    itemStyle: {
                                        color: '#36DDF7',
                                    }

                                }
                            ]

                        },
                        xAxis: [
                            {
                                data: val.pageInfo.x_data
                            },
                            {
                                //隐藏第二X轴
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    show: false
                                },
                                data: val.pageInfo.x_data
                            }
                        ],
                        yAxis: {
                            type: 'value',
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'solid',
                                    color: '#1c2962'
                                }
                            }
                        },//共用一个Y坐标轴
                        series: [

                            {
                                name: '个人正确的题',
                                type: 'bar',
                                barWidth: 24,
                                xAxisIndex: 0,//使用第二X轴
                                // data: [100, 2379, 2451, 4351, 4351, 4351],
                                data: val.pageInfo.data[1].data,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#6690FF'
                                },
                            },
                            {
                                name: '个人答题题量',
                                type: 'bar',
                                barWidth: 24,
                                xAxisIndex: 1,
                                // data: [3600, 3379, 5451, 3351, 3351, 3351],
                                data: val.pageInfo.data[0].data,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#ACC7FF'
                                },
                                z: '0'
                            },

                            {
                                name: '全员平均正确的题',
                                type: 'bar',
                                barWidth: 24,
                                xAxisIndex: 1,//使用第一X轴

                                data: val.pageInfo.data[3].data,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#36DDF7'
                                },

                            },
                            {
                                name: '全员平均答题题量',
                                type: 'bar',
                                barWidth: 24,
                                data: val.pageInfo.data[2].data,
                                itemStyle: {
                                    // 设置柱状图的颜色
                                    color: '#D6FEF7'
                                },
                                z: '0'
                            },

                        ]
                    }

                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    methods: {
        initChart(data) {
            var that = this

            const myChart = echarts.init(this.$refs.myChart,);
            myChart.setOption(
                data
                // {
                //     tooltip: {
                //         trigger: 'axis',
                //         axisPointer: {
                //             type: 'shadow'
                //         },
                //         formatter: function (params) {
                //             // params 是一个数组，数组中包含每个系列的数据信息
                //             // debugger
                //             let result = '';
                //             // debugger
                //             // result = `${params[0].seriesName}: ${params[0].value}<br/>` +
                //             //     `${params[2].seriesName}: ${params[3].value}<br/>` +
                //             //     `${params[1].seriesName}: ${params[1].value}<br/>` +
                //             //     `${params[3].seriesName}: ${params[3].value}<br/>`;

                //             result = `<div>` +
                //                 `<div calss="hover_title">${params[0].axisValue} : </div>` +
                //                 `<div class="hover_box"><div class="yuan color_1"></div><div>${params[2].seriesName}: ${params[2].value}</div></div>` +
                //                 `<div class="hover_box"><div class="yuan color_2"></div><div>${params[0].seriesName}: ${params[0].value}</div></div>` +
                //                 `<div class="hover_box"><div class="yuan color_3"></div><div>${params[1].seriesName}: ${params[1].value}</div></div>` +
                //                 `<div class="hover_box"><div class="yuan color_4"></div><div>${params[3].seriesName}: ${params[3].value}</div></div>` +
                //                 `</div>`
                //             return result;
                //         }
                //     },
                //     title: {

                //     },
                //     grid: {
                //         left: '3%',
                //         right: '4%',
                //         bottom: '3%',
                //         containLabel: true
                //     },
                //     legend: {
                //         // selectedMode: false,
                //         right: 30,
                //         top: 10,
                //         bottom: 20,
                //         textStyle: {
                //             color: "#fff",
                //         },
                //         data: [
                //             {
                //                 icon: 'circle',
                //                 name: "个人答题题量",
                //                 itemStyle: {
                //                     color: '#ACC7FF',
                //                 }
                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "个人正确的题",
                //                 itemStyle: {
                //                     color: '#6690FF',
                //                 }

                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "全员平均答题题量",
                //                 itemStyle: {
                //                     color: '#D6FEF7',
                //                 }

                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "全员平均正确的题",
                //                 itemStyle: {
                //                     color: '#36DDF7',
                //                 }

                //             }
                //         ]

                //     },
                //     xAxis: [
                //         {
                //             data: ["5.1", "5.2", "5.3", "5.4", "5.5", "5.6"]
                //         },
                //         {
                //             //隐藏第二X轴
                //             axisLine: {
                //                 show: false
                //             },
                //             axisTick: {
                //                 show: false
                //             },
                //             axisLabel: {
                //                 show: false
                //             },
                //             data: ["5.1", "5.2", "5.3", "5.4", "5.5", "5.6"]
                //         }
                //     ],
                //     yAxis: {
                //         type: 'value',
                //         splitLine: {
                //             show: true,
                //             lineStyle: {
                //                 type: 'solid',
                //                 color: '#1c2962'
                //             }
                //         }
                //     },//共用一个Y坐标轴
                //     series: [

                //         {
                //             name: '个人正确的题',
                //             type: 'bar',
                //             barWidth: 24,
                //             xAxisIndex: 0,//使用第二X轴
                //             data: [100, 2379, 2451, 4351, 4351, 4351],
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#6690FF'
                //             },
                //         },
                //         {
                //             name: '个人答题题量',
                //             type: 'bar',
                //             barWidth: 24,
                //             xAxisIndex: 1,
                //             data: [3600, 3379, 5451, 3351, 3351, 3351],
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#ACC7FF'
                //             },
                //             z: '0'
                //         },

                //         {
                //             name: '全员平均正确的题',
                //             type: 'bar',
                //             barWidth: 24,
                //             xAxisIndex: 1,//使用第一X轴

                //             data: [500, 600, 700, 800, 900, 1000],
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#36DDF7'
                //             },

                //         },
                //         {
                //             name: '全员平均答题题量',
                //             type: 'bar',
                //             barWidth: 24,
                //             data: [2455, 1379, 1451, 1351, 1351, 1351],
                //             itemStyle: {
                //                 // 设置柱状图的颜色
                //                 color: '#D6FEF7'
                //             },
                //             z: '0'
                //         },

                //     ]
                // }



            );
            this.$nextTick(() => {
                console.log("myChart.resize()");
                myChart.resize()
            })
        }
    }
};
</script>
<style>
.hover_box {
    display: flex;
    align-items: center;
}

.yuan {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
}

.color_1 {
    background-color: #ACC7FF;
}

.color_2 {
    background-color: #6690FF;
}

.color_3 {
    background-color: #D6FEF7;
}

.color_4 {
    background-color: #36DDF7;
}
</style>

