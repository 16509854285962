import * as exerciseApi from '@/api/exercise.js';
import * as utils from '@/utils';
import * as global from './global.js';

const QuestType = global.QuestType;
const StudyType = global.StudyType;
const PatternType = global.PatternType;

class QuestionWrapper {
  index = 0;
  question = null;
  operationSymbols = []; //选项的标识 A B C D
  operation = {
    answer: null,
    is_id: null, //本次答题的选项 单选和判断为数字  多选为1、2、3
    start_time: null, //本次进入该题的时间
    // end_time: null, //本次离开该题的时间
    res_time: null, //本次答该题的时间
    right_or_wrong: null, //本次答该题的对错
    isSigned: false, //是否标记
    isCollected: false, //是否收藏
    isAnswered: false, //是否答过
    isShowAnwser: false, //是否显示答案
    isShowAnalysis: true,//是否显示解析
  };
  clearOperation() {
    this.operation.answer = null;
    this.operation.is_id = null;
    this.operation.start_time = null;
    // this.operation.end_time = null;
    this.operation.res_time = null;
    this.operation.right_or_wrong = null;
    this.operation.isAnswered = false;
    this.operation.isShowAnwser = false;
  }
}


export default {

  computed: {

    m_QuestType() {
      return QuestType;
    },

    m_StudyType() {
      return StudyType;
    },

    m_PatternType() {
      return PatternType;
    },

    m_process() {
      if (!this.m_question_totle_num) {
        return {
          current: 0,
          total: 0,
          percentage: 0
        }
      }
      //测题模式
      const current = this.m_pattern_type == PatternType.CeTi ? this.m_answerNum : this.m_answerNum + this
        .m_question_answered_num;
      return {
        current: current,
        total: this.m_question_totle_num,
        percentage: parseInt(current * 100 / this.m_question_totle_num)
      }
    },
  },

  data() {
    return {
      m_questions: null,
      m_currentQuestion: null,

      //为测题模式下的多选题使用
      m_lastQuestion: null,

      m_sub_name: null,

      m_page: 0,
      m_totalPage: 0,
      m_answerNum: 0,

      m_question_totle_num: 0,
      m_question_answered_num: 0,

      m_goPage: 0,

    }
  },

  created() {
    //学习类型 全题库 章节 题型专考 错题专考 收藏专考
    this.m_study_type = null;
    //学习模式 背题 练题 测题
    this.m_pattern_type = null;
    //班级对应科目关系表ID
    this.m_pro_sub_id = null;
    //pro_id 从缓存得到
    //班级id
    this.m_pro_id = localStorage.getItem('proID');

    //题的序号 从哪道题开始
    this.m_serical = null;

    //题的类型 单选 多选 判断
    this.m_ques_type = null;

    //章节ID
    this.m_unit_id = null;



    this.m_pageResult = null;
    this.m_study_id = null;
    this.m_answerQueue = [];
    this.m_isPostAnswerQueueing = false;
    this.m_isSubmiting = 0;
    this.m_submitCallback = null;
    this.m_loadingInstance = null;

    this.m_isSubmited = false;


    this.m_handleWebRefreshBeforeunloadFn = null;
    this.m_handleWebRefreshKeydownFn = null;

    //记录用户所有的标记
    this.m_cache = {
      signs: [] // 为quesiton的id
    };
  },

  methods: {
    clearAllData() {
      this.m_questions = null,
        this.m_currentQuestion = null,

        //为测题模式下的多选题使用
        this.m_lastQuestion = null,

        this.m_sub_name = null,

        this.m_page = 0,
        this.m_totalPage = 0,
        this.m_answerNum = 0,

        this.m_question_totle_num = 0,
        this.m_question_answered_num = 0,

        this.m_goPage = 0,


        //学习类型 全题库 章节 题型专考 错题专考 收藏专考
        this.m_study_type = null;
      //学习模式 背题 练题 测题
      this.m_pattern_type = null;
      //班级对应科目关系表ID
      this.m_pro_sub_id = null;
      //pro_id 从缓存得到
      //班级id
      // this.m_pro_id = localStorage.getItem('proID');

      //题的序号 从哪道题开始
      this.m_serical = null;

      //题的类型 单选 多选 判断
      this.m_ques_type = null;

      //章节ID
      this.m_unit_id = null;



      this.m_pageResult = null;
      this.m_study_id = null;
      this.m_answerQueue = [];
      this.m_isPostAnswerQueueing = false;
      this.m_isSubmiting = 0;
      // this.m_submitCallback = null;
      this.m_loadingInstance = null;

      this.m_isSubmited = false;


    },

    m_showClearPageRecordModal() {
      this.confirmModalData = {
        ...this.confirmModalData,
        title: '确认清除本页的答题记录',
        info: '确认要清除本页的答题记录吗？',
        confirm: async () => {
          const loadingInstance = global.showLoading('清除中');

          //保存未提交的答题记录
          const res = await this._m_handleSaveBeforeSubmit();
          if (!res) {
            loadingInstance.close();
            global.showMessage('数据保存失败');
            return;
          }

          this.m_questions.forEach(item => {
            item.clearOperation();
          });
          this.m_answerNum = 0;
          this.m_answerQueue = [];
          loadingInstance.close();
          this.m_jumpToQuestion(this.m_questions[0].index);
        },
      }
      this.isShowConfirmModal = true;
    },
    m_showClearAllRecordModal() {
      this.confirmModalData = {
        ...this.confirmModalData,
        title: '确认清除所有的答题记录',
        info: '确认要清除所有的答题记录吗？',
        confirm: async () => {
          const loadingInstance = global.showLoading('清除中');

          //保存未提交的答题记录
          const res = await this._m_handleSaveBeforeSubmit();

          if (!res) {
            loadingInstance.close();
            global.showMessage('数据保存失败');
            return;
          }

          try {
            const res = await exerciseApi.studyWheel({
              study_type: this.m_study_type,
              pro_sub_id: this.m_pro_sub_id,
              pro_id: this.m_pro_id,
              handle_type: 1, //1:重新答题, 2:清空数据
              pattern_type: this.m_pattern_type,
              unit_id: this.m_unit_id,
              ques_type: this.m_ques_type,
            });

            loadingInstance.close();
            this.clearAllData();
            this.m_serical = 1;
            this.m_init(this.m_submitCallback);
          } catch (e) {
            global.showMessage('清除所有的答题记录失败');
            loadingInstance.close();
          }
        },
      }
      this.isShowConfirmModal = true;
    },
    m_isShowClearPageRecordBtn() {
      return this.m_pattern_type == PatternType.LianTi;
    },
    m_isShowClearAllRecordBtn() {
      return this.m_pattern_type == PatternType.LianTi && this.m_study_type != StudyType.CuoTiZhuanKao;
    },

    m_init(submitCallback) {
      this.m_pro_sub_id = this.$route.query.pro_sub_id;
      this.m_pattern_type = this.$route.query.pattern_type;
      this.m_study_type = this.$route.query.study_type;
      this.m_unit_id = this.$route.query.unit_id;
      //清除所有答题记录后 this.m_serical为1
      this.m_serical = this.m_serical == null ? this.$route.query.serical : this.m_serical;
      this.m_ques_type = this.$route.query.ques_type;


      const loadingInstance = global.showLoading('初始化');
      exerciseApi.studyCreate({
        study_type: this.m_study_type,
        pro_sub_id: this.m_pro_sub_id,
        pro_id: this.m_pro_id,
        pattern_type: this.m_pattern_type,
        unit_id: this.m_unit_id,
        serical: this.m_serical,
        ques_type: this.m_ques_type,
        //测试使用
        // serical: 3500,
        strip_num: global.Config.PageQuestionNum,
      }).then(res => {
        console.log('studyCreate', res);
        this.m_submitCallback = submitCallback;
        this.m_study_id = res.data.study_id;
        this.m_sub_name = res.data.sub_name;
        this._m_handle_init_data(res.data, true);
        loadingInstance.close();
      }).catch(e => {
        if (e.type == RequestApiException) {
          global.showMessage(e.error.msg);
          setTimeout(() => {
            this.$router.replace({
              path: "DKLXList"
            });
            loadingInstance.close();
          }, 2000);
        }
      })
    },

    async _m_handleRequestQuestionList(page) {
      //保存本页还没有提交的答题记录
      const loadingInstance = global.showLoading('翻页中');

      //保存未提交的答题记录
      const res = await this._m_handleSaveBeforeSubmit();

      if (!res) {
        loadingInstance.close();
        global.showMessage('本页答题记录保存失败，翻页失败');
        return;
      }
      try {
        const res = await exerciseApi.pagingQuesList({
          study_type: this.m_study_type,
          pro_sub_id: this.m_pro_sub_id,
          pattern_type: this.m_pattern_type,
          unit_id: this.m_unit_id,
          ques_type: this.m_ques_type,
          pro_id: this.m_pro_id,
          page: page,
          strip_num: global.Config.PageQuestionNum,
        });
        this._m_handle_init_data(res.data);
      } catch (e) {
        //TODO handle the exception
      }
      loadingInstance.close();
      // 答题卡滚动
    },

    m_showCheckAnswerButton(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      return question.ques_type == QuestType.MultipleChoise && !operation.isShowAnwser && this.m_pattern_type !=
        PatternType.CeTi;
    },

    m_correctAnswers(questionWP) {
      return questionWP.question.test_ques.filter(item => {
        return item.is_item;
      }).map((item, index) => {
        return item.item_chr;
      }).join('');
    },

    m_myAnswers(questionWP) {
      if (this.m_pattern_type == PatternType.BeiTi)
        return '-';
      const is_ids = questionWP.operation.is_id.toString().split(',').map(item => parseInt(item));
      const myAnswers = questionWP.question.test_ques.filter(item => {
        return is_ids.includes(item.is_id);
      }).map((item, index) => {
        return item.item_chr;
      }).join('');
      return myAnswers ? myAnswers : '-';
    },

    m_answerStatistics(questionWP) {
      const question = questionWP.question;
      const operation = questionWP.operation;

      //背题模式
      if (this.m_pattern_type == PatternType.BeiTi || question.your_answer) {
        return {
          total: question.count_num,
          correct_num: question.correct_num,
          error_num: question.error_num,
          correct_rate: question.correct_rate
        }
      }

      const total = question.count_num + 1;
      const correct_num = operation.right_or_wrong == 1 ? question.correct_num + 1 : question.correct_num;
      const error_num = operation.right_or_wrong == 0 ? question.error_num + 1 : question.error_num;
      const correct_rate = parseInt(correct_num * 100 / total);
      return {
        total,
        correct_num,
        error_num,
        correct_rate
      }
    },

    m_answerSheetStatus(operation) {
      //测题模式
      if (this.m_pattern_type == PatternType.CeTi)
        return operation.isAnswered ? 'block_2' : 'block_4';
      if (!operation.isAnswered)
        return 'block_4';
      if (operation.right_or_wrong)
        return 'block_2';
      else
        return 'block_3';
    },

    m_answerSheetFontShowCorrectColor(operation) {
      //测题模式
      if (this.m_pattern_type == PatternType.CeTi)
        return operation.isAnswered;
      return operation.right_or_wrong == 1;
    },

    m_answerSheetCorrectLogoFont() {
      //测题模式
      if (this.m_pattern_type == PatternType.CeTi)
        return '已答';
      return '正确';
    },

    m_showAnswerSheetErrorLogo() {
      //测题模式
      if (this.m_pattern_type == PatternType.CeTi)
        return false;
      return true;
    },

    m_previousPage() {
      if (this.m_page < 1)
        return;
      this._m_handleRequestQuestionList(this.m_page - 1);
    },

    m_nextPage() {
      if (this.m_page >= this.m_totalPage)
        return;
      this._m_handleRequestQuestionList(this.m_page + 1);
    },

    m_jumpPage(page) {
      if (page > 0 && page <= this.m_totalPage) {
        this._m_handleRequestQuestionList(page);
      }
    },

    m_jumpToQuestion(index, behavior = 'smooth') {
      document.querySelector('#question_' + index).scrollIntoView({
        behavior
      });
      this.m_currentQuestion = this.m_questions.find(item => item.index == index);
    },

    m_answer(currentQuestion, is_id) {
      // if (this.m_isSubmited)
      //   return false;

      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      this.m_currentQuestion = currentQuestion;

      if (operation.isAnswered)
        return false;

      //练题和测题模式
      if (this.m_pattern_type != PatternType.BeiTi && currentQuestion != this.m_lastQuestion) {
        if (this.m_lastQuestion && !this.m_lastQuestion.operation.isAnswered) {
          this.m_handleAnswered(this.m_lastQuestion);
          this.m_lastQuestion.operation.isShowAnwser = false;
        }
        if (currentQuestion.question.ques_type == QuestType.MultipleChoise && !operation.isAnswered) {
          this.m_lastQuestion = currentQuestion;
        }
      }


      operation.right_or_wrong = 0;
      operation.res_time = parseInt(Date.now() / 1000);
      if (question.ques_type == QuestType.SingleChoise || question.ques_type == QuestType.Judge) {
        operation.is_id = is_id;
        for (let i = 0; i < question.test_ques.length; i++) {
          if (question.test_ques[i].is_item && question.test_ques[i].is_id == is_id) {
            operation.right_or_wrong = 1;
            break;
          }
        }
      } else if (question.ques_type == QuestType.MultipleChoise) {
        if (operation.is_id == null) {
          operation.is_id = is_id.toString();
        } else {
          const is_ids = new Set(operation.is_id.split(',').map(item => parseInt(item)));
          if (is_ids.has(is_id))
            is_ids.delete(is_id);
          else
            is_ids.add(is_id);
          operation.is_id = is_ids.size == 0 ? null : Array.from(is_ids).sort().join(',');
        }
        if (operation.is_id) {
          const is_ids = question.test_ques.filter(item => {
            return item.is_item;
          }).map(item => {
            return item.is_id;
          }).sort().join(',');
          operation.right_or_wrong = operation.is_id == is_ids ? 1 : 0;
        }
      }
      this.$set(questionWrapper, 'operation', operation);
    },



    m_handleAnswered(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;

      if (operation.isAnswered)
        return;
      if (!operation.is_id)
        return;

      operation.isAnswered = true;
      operation.isShowAnwser = true;
      this._m_save(currentQuestion);
      this.m_answerNum++;
    },

    m_canUIShowAnsered(currentQuestion) {
      return currentQuestion.operation.isShowAnwser && this.m_pattern_type != PatternType.CeTi;
      // return currentQuestion.operation.isAnswered && this.m_pattern_type != PatternType.CeTi;
    },

    m_sign(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      this.$set(operation, 'isSigned', !operation.isSigned);
      if (operation.isSigned) {
        if (!this.m_cache.signs.includes(question.id))
          this.m_cache.signs.push(question.id);
      } else {
        this.m_cache.signs = this.m_cache.signs.filter(item => item != question.id);
      }
    },

    m_collect(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;

      exerciseApi.collect({
        pro_sub_id: this.m_pro_sub_id,
        pro_id: this.m_pro_id,
        ques_id: question.id,
        ver_ques_id: question.ver_ques_id,
      }).then(res => {
        this.$set(operation, 'isCollected', res.data.is_collect);
      })
    },

    m_checkOptionSelected(currentQuestion, option) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      let selected = null;

      const is_ids = operation.is_id ? operation.is_id.toString().split(',').map(item => parseInt(item)) : [];
      if (this.m_pattern_type == PatternType.LianTi && operation.isAnswered && option.is_item) {
        selected = 1;
      }
      if (is_ids.includes(option.is_id)) {
        //测题模式
        if (this.m_pattern_type == PatternType.CeTi)
          selected = 0;
        else if (!operation.isAnswered)
          selected = 0;
        else
          selected = option.is_item ? 1 : -1;
      }
      return selected;
    },


    _m_save(currentQuestion) {
      const questionWrapper = currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      if (operation.is_id == null)
        return;

      const answer = {
        answer: operation.is_id,
        end_time: operation.res_time,
        number: 1,
        pattern_type: this.m_pattern_type,
        ques_id: question.id,
        ques_type: question.ques_type,
        res_time: operation.res_time,
        right_or_wrong: operation.right_or_wrong,
        serical: question.serical,
        start_time: operation.res_time,
        ver_ques_id: question.ver_ques_id,
        wheel_num: question.wheel_num,
        unit_id: this.m_study_type == global.StudyType.ZhangJie ? question.unit_id : undefined,
      };

      //去重
      this.m_answerQueue = this.m_answerQueue.filter(item => {
        return item.ques_id != answer.ques_id;
      });
      this.m_answerQueue.push(answer);
      this._m_postAnswerQueue();
    },

    async _m_postAnswerQueue(checkNumber = true) {
      if (this.m_isPostAnswerQueueing ||
        checkNumber && this.m_answerQueue.length < global.Config.MaxPostQuestionNum ||
        this.m_answerQueue.length == 0) {
        return;
      }

      this.m_isPostAnswerQueueing = true;

      const answers = [...this.m_answerQueue];
      this.m_answerQueue = [];
      let postData = JSON.parse(JSON.stringify(answers));

      try {
        const res = await exerciseApi.studyRecord({
          study_id: this.m_study_id,
          study_record: postData
        });
      } catch (e) {
        //去重
        postData = postData.filter(item => {
          return !this.m_answerQueue.find(a => {
            a.ques_id == item.ques_id;
          })
        });
        this.m_answerQueue = postData.concat(this.m_answerQueue);
      }
      this.m_isPostAnswerQueueing = false;
    },

    async m_submit() {
      if (this.m_isSubmiting)
        return;
      this.m_isSubmiting = 1;
      this.m_submitCallback && this.m_submitCallback.beforSubmit();
      this.m_loadingInstance = global.showLoading('保存中');
      const startTime = Date.now();

      //保存未提交的答题记录
      let res = await this._m_handleSaveBeforeSubmit();
      if (!res) {
        this._m_handleSubmitException();
        return;
      }

      //让用户看到交卷中的提示
      const duration = global.Config.MaxSubmitShowTipTime * 1000 - (Date.now() - startTime);
      if (duration > 0) {
        await utils.waitSleep(duration);
      }

      this.m_loadingInstance.close();
      this.m_submitCallback && this.m_submitCallback.afterSubmit(true);
      this.m_submitCallback = null;

      console.log('------------交卷成功------------');
      // this._m_clearStorage();
    },


    _m_handle_init_data(data, pageCreate = false) {
      this.m_pageResult = data;
      this.m_answerQueue = [];

      this.m_page = this.m_pageResult.page;
      this.m_goPage = this.m_page;
      this.m_totalPage = this.m_pageResult.totalPages;

      this.m_question_totle_num = data.total;
      this.m_question_answered_num = typeof data.answer_count == 'undefined' ? 0 : data.answer_count;

      this.m_answerNum = 0;

      this._m_loadData();

      //背题模式
      if (this.m_pattern_type == PatternType.BeiTi) {
        this._m_changeToBeiTi();
      }

      //测题模式
      if (this.m_pattern_type == PatternType.CeTi) {
        this._m_changeToCeTi();
      }


      if (this.m_pattern_type == PatternType.LianTi) {
        //如果返回的数据有now_serical则定位到now_serical
        if (pageCreate && this.m_pageResult.now_serical) {
          this.$nextTick(() => {
            this.m_jumpToQuestion(this.m_pageResult.now_serical - 1, 'auto');
          });
        } else {
          //定位到最前面没有回答过的题
          this.$nextTick(() => {
            let questionWP = this.m_questions.find(item => {
              return !item.operation.isAnswered;
            });
            questionWP = questionWP ? questionWP : this.m_questions[0];
            this.m_jumpToQuestion(questionWP.index, 'auto');
          });
        }
      }

      //答题卡重回顶部
      document.querySelector('#card_list_body').scrollTo({
        top: 0
      });

    },


    async _m_handleSaveBeforeSubmit() {

      //测题、练题模式 处理最后一道多选题
      if (this.m_pattern_type != PatternType.BeiTi && this.m_lastQuestion) {
        this.m_handleAnswered(this.m_lastQuestion);
      }

      while (this.m_isPostAnswerQueueing) {
        await utils.waitSleep(500);
      }

      //保存答题接口MaxAnswersSaveTime调用必须成功
      if (this.m_answerQueue.length == 0)
        return true;
      let times = global.Config.MaxAnswersSaveTime;
      while (--times >= 0 && this.m_answerQueue.length > 0) {
        await this._m_postAnswerQueue(false);
        if (this.m_answerQueue.length == 0)
          return true;
        await utils.waitSleep(1000);
      }
      return false;
    },

    _m_handleSubmitException() {
      this.m_isSubmited = true;
      this.m_loadingInstance.close();
      this.m_submitCallback && this.m_submitCallback.afterSubmit(false);
      this.m_isSubmiting = 0;
      global.showMessage('保存退出失败');
    },

    _m_loadData() {
      let index = (this.m_page - 1) * global.Config.PageQuestionNum;
      this.m_questions = this.m_pageResult.ques.map(item => {
        const questionWrapper = new QuestionWrapper();
        questionWrapper.question = item;
        questionWrapper.index = index;
        questionWrapper.operation.isCollected = questionWrapper.question.is_collect;
        questionWrapper.operationSymbols = questionWrapper.question.test_ques.map((v,index) => {
          return String.fromCharCode(65 + index);
        });
        if (item.your_answer) {
          questionWrapper.operation.right_or_wrong = item.right_or_wrong;
          questionWrapper.operation.isAnswered = true;
          questionWrapper.operation.is_id = item.your_answer;
          questionWrapper.operation.isShowAnwser = true;
        }
        if (this.m_cache.signs.includes(questionWrapper.question.id)) {
          questionWrapper.operation.isSigned = true;
        }
        index++;
        return questionWrapper;
      });
      this.m_currentQuestion = null;
      this.m_lastQuestion = null;
    },

    _m_changeToBeiTi() {
      this.m_questions.forEach(item => {
        item.operation.isAnswered = true;
        item.operation.isShowAnwser = true;
        item.operation.is_id = item.question.test_ques.filter(item => item.is_item).map(item => item.is_id).join(
          ',');
        item.operation.right_or_wrong = 1;
      })
    },

    _m_changeToCeTi() {
      this.m_questions.forEach(item => {
        item.operation.isAnswered = false;
        item.operation.is_id = null;
        item.operation.right_or_wrong = null;
      })
    },




    m_openWebRefreshListener() {

      this.m_handleWebRefreshBeforeunloadFn = (e) => {
        // Cancel the event as stated by the standard.

        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        // Optionally display a confirmation dialog (only in some browsers)
        return '确定要刷新浏览器吗，你本页的答题记录都会丢弃？';
      }

      this.m_handleWebRefreshKeydownFn = (e) => {
        if ((e.key === 'F5') || (e.ctrlKey && e.key === 'r')) {

          // Prevent the default refresh behavior
          e.preventDefault();
          // Optionally display a confirmation dialog
          if (confirm('确定要刷新浏览器吗，你本页的答题记录都会丢弃？')) {
            window.location.reload();
          }
        }
      }

      window.addEventListener('beforeunload', this.m_handleWebRefreshBeforeunloadFn);

      // Capture F5 keypress and Ctrl+R
      // document.addEventListener('keydown', this.m_handleWebRefreshKeydownFn);
    },

    m_closeWebRefreshListener() {
      window.removeEventListener('beforeunload', this.m_handleWebRefreshBeforeunloadFn);
      // document.removeEventListener('keydown', this.m_handleWebRefreshKeydownFn);
    }


  }
}
