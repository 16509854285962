import * as examinationApi from '@/api/examination.js';
import * as utils from '@/utils';
import * as global from './global.js';

import {
  Loading,
  Message
} from 'element-ui';

const MAX_POST_QUESTION_NUM = 10;
const MAX_SUBMIT_SHOW_TIP_TIME = 2;
const MAX_SUBMIT_TIME = 3;
const MAX_ANSWERS_SAVE_TIME = 3;

const QuestType = global.QuestType;

const SubmitType = global.ExamSubmitType;

const SubmitTips = {
  [SubmitType.UserSubmit]: '交卷中',
  [SubmitType.TimeIsUp]: '答题时间结束，自动交卷中',
  [SubmitType.AppSwitchBack]: '应用切换到后台，自动交卷中',
  [SubmitType.ForceEnd]: '本场考试结束，自动交卷中',
}

//考试类型
export const ExamType = global.ExamType;

class QuestionWrapper {
  index = 0;
  question = null;
  operationSymbols = []; //选项的标识 A B C D
  operation = {
    answers: [], //总共答题的次数
    is_id: null, //本次答题的选项 单选和判断为数字  多选为1、2、3
    start_time: null, //本次进入该题的时间
    // end_time: null, //本次离开该题的时间
    res_time: null, //本次答该题的时间
    right_or_wrong: null, //本次答该题的对错
    isSigned: false, //是否标记
    isCollected: 0, //是否收藏
  };
  clearOperation() {
    this.operation.is_id = null;
    this.operation.start_time = null;
    // this.operation.end_time = null;
    this.operation.res_time = null;
    this.operation.right_or_wrong = null;
  }
}

// type cache = {
//   operations: [],
//   questionIndex: 0,
//   answerQueue: [],
//   startTime:0,
//   isSubmited:false,
//   lastSubmitType:null,
// }

export default {
  data() {
    return {
      m_questionIndex: null,
      m_currentQuestion: null,
    }
  },

  computed: {
    m_isMoKao() {
      return this.$route.query.examType == ExamType.MoKao;
    },
    m_isZhuanKao() {
      return this.$route.query.examType == ExamType.ZhuanKao;
    },
    m_currentQuestions() {
      const questions = [null, null];
      if (this.m_questionIndex != null && this.m_questions) {
        const index = this.m_questionIndex % 2;
        questions[index] = this.m_questions[this.m_questionIndex];
      }
      return questions;
    },

    m_process() {
      if (this.m_questionIndex == null) {
        return {
          current: 0,
          total: 0,
          percentage: 0
        }
      }
      const current = this.m_questions.reduce((num, item) => {
        return num + (this.m_isQuestionAnswered(item) > 0 ? 1 : 0);
      }, 0);
      return {
        current: current,
        total: this.m_questions.length,
        percentage: this.m_questions.length == 0 ? 0 : (current * 100 / this.m_questions.length)
      }
    },

    m_questionSheet() {
      if (this.m_questionIndex != null) {
        return this.m_questions;
      }
      return [];
    }
  },

  created() {
    //缓存的key
    this.m_grant_id = null;
    this.m_exam = null;
    this.m_questions = null;
    this.m_answerQueue = [];
    this.m_isPostAnswerQueueing = false;
    this.m_isSubmiting = 0;
    this.m_submitCallback = null;
    this.m_SubmitType = SubmitType;
    this.m_loadingInstance = null;

    this.m_isSubmited = false;
    this.m_lastSubmitType = null;

    this.m_cache = null;
  },

  methods: {
    m_init(submitCallback) {
      this.m_submitCallback = submitCallback;
      this.m_exam = JSON.parse(localStorage.getItem("exam_ques"));
      if (this.m_isMoKao) {
        this.m_grant_id = 'mokao_' + this.m_exam.exam_id;
      } else if (this.m_isZhuanKao) {
        this.m_grant_id = 'zhuankao_' + this.m_exam.exam_id;
      }else {
        this.m_grant_id = 'tongkao_' + this.m_exam.exam_id;
      }
      this._m_initCache();
      this._m_loadData();
      this._m_handleChangeQuestion();
    },

    m_canPrevious() {
      return this.m_questionIndex > 0;
    },

    m_previous() {
      if (this.m_isSubmited || this.m_questionIndex == 0) {
        return false;
      }
      this._m_handleExitQuestion();
      this.m_questionIndex--;
      this._m_handleChangeQuestion();
      return true;
    },

    m_canNext() {
      return this.m_questionIndex < this.m_questions.length - 1;
    },

    m_next() {
      if (this.m_isSubmited || this.m_questionIndex == this.m_questions.length - 1) {
        return false;
      }
      this._m_handleExitQuestion();
      this.m_questionIndex++;
      this._m_handleChangeQuestion();
      return true;
    },

    m_canJump(index) {
      return index != this.m_questionIndex;
    },

    m_jump(index) {
      if (this.m_isSubmited || index == this.m_questionIndex)
        return false;
      this._m_handleExitQuestion();
      this.m_questionIndex = index;
      this._m_handleChangeQuestion();
    },

    m_answer(is_id) {
      if (this.m_isSubmited)
        return false;
      const questionWrapper = this.m_currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      operation.right_or_wrong = 0;
      operation.res_time = parseInt(Date.now() / 1000);
      if (question.ques_type == QuestType.SingleChoise || question.ques_type == QuestType.Judge) {
        operation.is_id = is_id;
        for (let i = 0; i < question.test_ques.length; i++) {
          if (question.test_ques[i].is_item && question.test_ques[i].is_id == is_id) {
            operation.right_or_wrong = 1;
            break;
          }
        }
      } else if (question.ques_type == QuestType.MultipleChoise) {
        if (operation.is_id == null) {
          operation.is_id = is_id.toString();
        } else {
          const is_ids = new Set(operation.is_id.split('、').map(item => parseInt(item)));
          if (is_ids.has(is_id))
            is_ids.delete(is_id);
          else
            is_ids.add(is_id);
          operation.is_id = is_ids.size == 0 ? null : Array.from(is_ids).sort().join('、');
        }
        if (operation.is_id) {
          const is_ids = question.test_ques.filter(item => {
            return item.is_item;
          }).map(item => {
            return item.is_id;
          }).sort().join('、');
          operation.right_or_wrong = operation.is_id == is_ids ? 1 : 0;
        }
      }
      this.$set(questionWrapper, 'operation', operation);
    },

    m_sign() {
      const questionWrapper = this.m_currentQuestion;
      const operation = questionWrapper.operation;
      this.$set(operation, 'isSigned', !operation.isSigned);
      this._m_saveOperationsToCache();
    },

    //模考收藏
    m_collect() {
      const questionWrapper = this.m_currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;

      examinationApi.moExamCollect({
        pro_sub_id: this.eaxmBase.pro_sub_id,
        practice_id: this.eaxmBase.practice_id,
        ques_id: question.id,
        ver_ques_id: question.ver_ques_id,
      }).then(res => {
        this.$set(operation, 'isCollected', res.data.is_collect);
        this._m_saveOperationsToCache();
      })
    },

    m_checkOptionSelected(option) {
      const questionWrapper = this.m_currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;

      if (question.ques_type == QuestType.SingleChoise || question.ques_type == QuestType.Judge) {
        return operation.is_id == option.is_id;
      } else if (question.ques_type == QuestType.MultipleChoise) {

        if (!operation.is_id)
          return false;
        return operation.is_id.split('、').map(item => parseInt(item)).includes(option.is_id);
      }
    },

    m_isQuestionAnswered(questionWrapper) {
      const operation = questionWrapper.operation;
      return operation.answers.length > 0 || operation.is_id != null;
    },

    _m_save() {
      const questionWrapper = this.m_currentQuestion;
      const question = questionWrapper.question;
      const operation = questionWrapper.operation;
      if (operation.is_id != null) {
        if (operation.answers.length > 0 && operation.answers[operation.answers.length - 1].answer != operation
          .is_id ||
          operation.answers.length == 0) {
          operation.answers.push({
            answer: operation.is_id,
            id: question.record_id,
            ques_id: question.id,
            ques_type: question.ques_type,
            ver_ques_id: question.ver_ques_id,
            number: operation.answers.length + 1,
            start_time: operation.start_time,
            end_time: parseInt(Date.now() / 1000),
            res_time: operation.res_time,
            right_or_wrong: operation.right_or_wrong
          });

          this._m_saveOperationsToCache();

          if (!this.m_answerQueue.includes(questionWrapper)) {
            this.m_answerQueue.push(questionWrapper);
            this._m_saveAnswerQueueToCache();
            this._m_postAnswerQueue();
          }
        }
      }
      questionWrapper.clearOperation();
    },

    _m_handleExitQuestion() {
      this._m_save();
    },

    _m_handleChangeQuestion() {
      this.m_currentQuestion = this.m_questions[this.m_questionIndex];
      const question = this.m_currentQuestion.question;
      const operation = this.m_currentQuestion.operation;

      this.m_currentQuestion.clearOperation();
      operation.start_time = parseInt(Date.now() / 1000);
      operation.is_id = operation.answers.length > 0 ? operation.answers[operation.answers.length - 1].answer :
        null;
      this.$set(this.m_currentQuestion, 'operation', operation);

      this._m_saveQuestionIndexToCache();
    },

    async _m_postAnswerQueue(checkNumber = true) {
      if (this.m_isPostAnswerQueueing ||
        checkNumber && this.m_answerQueue.length < MAX_POST_QUESTION_NUM ||
        this.m_answerQueue.length == 0) {
        return;
      }

      this.m_isPostAnswerQueueing = true;

      const questionWrappers = [...this.m_answerQueue];
      this.m_answerQueue = [];

      //去重 & 最后一次答题的答案
      let postData = Array.from(new Set(questionWrappers)).map(item => {
        const answers = item.operation.answers;
        return answers[answers.length - 1];
      });
      postData = JSON.parse(JSON.stringify(postData));

      try {
        const res = await examinationApi.examRecord({
          exam_id: this.m_exam.exam_id,
          study_record: postData
        });
        this._m_saveAnswerQueueToCache();
        //考试结束，强制交卷
        if (!this.m_isSubmiting && res.code == 800002) {
          this.m_submit(SubmitType.ForceEnd, true);
        }
      } catch (e) {
        this.m_answerQueue = Array.from(new Set(questionWrappers.concat(this.m_answerQueue)));
        this._m_saveAnswerQueueToCache();
      }

      this.m_isPostAnswerQueueing = false;
    },

    async m_submit(submitType, onlySubmit = false) {
      if (this.m_isSubmiting)
        return;
      this.m_isSubmiting = submitType;
      this.m_submitCallback && this.m_submitCallback.beforSubmit();
      this.m_loadingInstance = Loading.service({
        text: this.m_isSubmited ? '交卷中' : SubmitTips[submitType],
        background: "rgba(0,0,0,.3)"
      });
      const startTime = Date.now();

      if (!onlySubmit) {
        this._m_save();
        while (this.m_isPostAnswerQueueing) {
          await utils.waitSleep(500);
        }

        const res = await this._m_handleSaveBeforeSubmit();
        if (!res) {
          this._m_handleSubmitException();
          return;
        }
      }

      const res = await this._m_handleSubmit();
      if (!res) {
        this._m_handleSubmitException();
        return;
      }

      //让用户看到交卷中的提示
      const duration = MAX_SUBMIT_SHOW_TIP_TIME * 1000 - (Date.now() - startTime);
      if (duration > 0) {
        await utils.waitSleep(duration);
      }

      this.m_loadingInstance.close();
      this.m_submitCallback && this.m_submitCallback.afterSubmit(true);
      this.m_submitCallback = null;

      this._m_clearStorage();
    },

    async _m_handleSaveBeforeSubmit() {
      //保存答题接口MAX_ANSWERS_SAVE_TIME调用必须成功
      let times = MAX_ANSWERS_SAVE_TIME;
      while (--times >= 0 && this.m_answerQueue.length > 0) {
        await this._m_postAnswerQueue(false);
        await utils.waitSleep(1000);
      }
      return this.m_answerQueue.length == 0;
    },

    async _m_handleSubmit() {
      //交卷接口MAX_SUBMIT_TIME调用必须成功
      let times = MAX_SUBMIT_TIME;
      let res = false;
      while (--times >= 0) {
        try {
          await examinationApi.examSubmit({
            exam_id: this.m_exam.exam_id,
            submit_type: this.m_isSubmiting
          });
          res = true;
          break;
        } catch (e) {

        }
        await utils.waitSleep(1000);
      }
      return res;
    },

    _m_handleSubmitException() {
      this.m_lastSubmitType = this.m_isSubmiting;
      this.m_isSubmited = true;
      this._m_saveSubmitExceptionToCache();
      this.m_loadingInstance.close();
      this.m_submitCallback && this.m_submitCallback.afterSubmit(false);
      this.m_isSubmiting = 0;
    },

    m_isSubmitedByUser(type) {
      return type == SubmitType.UserSubmit;
    },


    _m_initCache() {
      this.m_cache = JSON.parse(localStorage.getItem('exam_storage'));
      if (this.m_cache == null || this.m_cache.grant_id != this.m_grant_id) {
        this.m_cache = {
          grant_id: this.m_grant_id,
        };
      }
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    _m_loadData() {
      if (this.m_cache.operations) {
        let index = 0;
        //还原答题记录
        this.m_questions = this.m_exam.ques.map(item => {
          const questionWrapper = new QuestionWrapper();
          questionWrapper.question = item;
          questionWrapper.operation = this.m_cache.operations[index];
          questionWrapper.index = index;
          questionWrapper.operationSymbols = questionWrapper.question.test_ques.map((v, index) => {
            return String.fromCharCode(65 + index);
          });
          index++;
          return questionWrapper;
        });

        //还原保存记录
        if (this.m_cache.answerQueue) {
          this.m_cache.answerQueue.forEach(index => {
            this.m_answerQueue.push(this.m_questions[index]);
          });
        }

        //还原上次交卷异常
        this.m_isSubmited = this.m_cache.isSubmited ? true : false;
        this.m_lastSubmitType = this.m_cache.lastSubmitType;

        this.m_questionIndex = this.m_cache.questionIndex;
      } else {
        let index = 0;
        this.m_questions = this.m_exam.ques.map(item => {
          const questionWrapper = new QuestionWrapper();
          questionWrapper.question = item;
          questionWrapper.index = index;
          questionWrapper.operation.isCollected = item.is_collect;
          questionWrapper.operationSymbols = questionWrapper.question.test_ques.map((v, index) => {
            return String.fromCharCode(65 + index);
          });
          index++;
          return questionWrapper;
        });
        this.m_questionIndex = 0;
      }
    },

    _m_saveOperationsToCache() {
      this.m_cache.operations = this.m_questions.map(item => {
        return item.operation;
      });
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    _m_saveAnswerQueueToCache() {
      this.m_cache.answerQueue = this.m_answerQueue.map(item => {
        return item.index;
      });
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    _m_saveQuestionIndexToCache() {
      this.m_cache.questionIndex = this.m_questionIndex;
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    m_saveStartTimeToCache(startTime) {
      this.m_cache.startTime = startTime;
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    _m_saveSubmitExceptionToCache() {
      this.m_cache.isSubmited = this.m_isSubmited;
      this.m_cache.lastSubmitType = this.m_lastSubmitType;
      localStorage.setItem('exam_storage', JSON.stringify(this.m_cache));
    },

    _m_clearStorage() {
      this.m_cache = null;
      global.clearExamStorage();
    },

  }
}
