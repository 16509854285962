<template>
    <div ref="myChart" id="charts2"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>

import * as echarts from 'echarts';

export default {
    name: 'MyChart2',
    props: {
        pageData: Object
    },

    data() {
        return {
            is1920: false,
            chartsSize: 100,
            // XData: [],
            pageInfo: {},
            pageColor: [],
            chartsInfo: null,
            myChart: null
        }
    },
    watch: {
        immediate: true,
        deep: true,
        pageData: {
            
            handler: function (val, oldval) {

                var XData = []
                if (val.pageInfo) {
                    this.pageData = val
                    this.pageInfo = val.pageInfo
                    this.pageColor = val.pageColor
                    for (let i = 0; i < val.pageInfo.x_len; i++) {
                        XData.push(i + 1)
                    }
                    this.chartsInfo = {
                        radar: {
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#000'
                                    // width: 1,
                                    // type: 'solid'
                                }
                            }
                        },
                        radius: 170,
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            type: "plain",
                            right: 50,
                            textStyle: {
                                color: "#fff"
                            },
                            data: this.setLegendData(val.pageInfo.data, val.pageColor)
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                                saveAsImage: {}
                            }
                        },
                        xAxis: {
                            name: "题号",
                            nameTextStyle: {
                                color: "#fff"
                            },
                            type: 'category',
                            nameLocation: "end",

                            data: XData,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#555555",
                                    align: "center"
                                    // width: 0,
                                    // type: "solid",
                                }, // x轴线的颜色以及宽度
                            },

                            axisLabel: {
                                showMaxLabel: true,       // 显示最大刻度
                                showMinLabel: true,      // 显示最小刻度
                                align: 'left'
                            },

                        },
                        yAxis: {
                            type: 'value',
                            name: "时间/秒",
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: "#fff",
                                    opacity: 0.2,
                                }, // 设置横向的线的颜色
                            },
                            nameTextStyle: {
                                color: "#fff"
                            },
                        },
                        series: this.setData(val.pageInfo.data, val.pageColor)
                    }
                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
    },
    methods: {
        setLegendData(info, color) {
            let tempArr = []
            for (let i = 0; i < info.length; i++) {
                let temp = {
                    icon: 'circle',
                    name: info[i].title,
                    itemStyle: {
                        color: color[i]

                    }
                }
                tempArr[i] = temp
            }
            return tempArr
        },
        setData(info, color) {
            let tempArr = []
            for (let i = 0; i < info.length; i++) {
                let tempObj = {
                    name: info[i].title,
                    type: 'line',
                    symbol: 'circle', //变为实心圆
                    data: info[i].data,
                    showSymbol: false,
                    smooth: false,  //使线条顺滑
                    itemStyle: { //折线的样式设置
                        normal: {
                            color: color[i],
                            lineStyle: { //折线线条的设置
                                color: color[i],
                                width: 1
                            },
                        },
                    },
                    emphasis: {
                        color: '#00ff00' // 高亮（hover）状态下的圆点颜色
                    }
                }
                tempArr[i] = tempObj
            }
            return tempArr
        },
        initChart(data) {
      
            if (!this.pageInfo) {
                return false
            }
            if(this.myChart){
                
                this.myChart.clear();
                this.myChart = null
            }
            this.myChart = echarts.init(this.$refs.myChart);

            this.myChart.setOption(
                data
            );
            this.$nextTick(() => {
                console.log("   myChart.resize() 考试每道题时间曲线");
                this.myChart.resize()
            })
        }
    }
};
</script>
<style></style>

