import { request } from "../utils/request"


export function _getExamList(data) {
    return request({
        url: 'stuwebapi/study.exam/getExamList',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}



