<template>
  <div class="end_box">
    <div class="row1">
      <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank" style="margin: 0 12px;"> 蜀ICP备2024049626号-1</a>
      <img src="https://resources.sxsaas.com/static/jigou-saas-app/jc.png" alt="" style="margin-right: 4px;">
      <a href="https://beian.mps.gov.cn/#/query/websearch?code=51019002006273" rel="noreferrer"
      target="_blank" > 川公网安备51019002006273</a>
      <div style="margin: 0 12px;">四川拾星科技有限公司版权所有</div>
    </div>

  </div>
</template>
  

<script>

export default {
  name:"endBox",
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {

  },
};
</script>

<style scoped>
  .end_box {
    width: 100%;
    height: 100%;
    background: rgba(16, 26, 42, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 14px;
    
  }
  .end_box .row1 {
    display: flex;
  }
  .row1 img {
    width: 16px;
    height: 16px;
  }
  a {
    text-decoration: none;
     color: #fff;
     font-size: 12px;
  }
  .row1 div {
    color: #fff;
    font-size: 12px;
  }
</style>

