<template>
    <div ref="myChart"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';
// prettier-ignore

// prettier-ignore
// const data = [
//     [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
//     [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
//     [0, 22, 50], [0, 23, 100]]
//     .map(function (item) {
//         return [item[1], item[0], item[2] || '-'];
//     });

export default {
    name: 'MyChart7',
    props: {
        pageData: Object
    },
    watch: {

        immediate: true,
        deep: true,
        pageData: {

            handler: function (val, oldval) {

                if (val) {

                    this.pageInfoSeven = val.seven
                    this.pageInfoAll = val.all
                    this.pageColor = val.pageColor

                    console.log(this.pageInfo);
                    console.log(this.pageColor);

                    this.chartsInfo = {
                        color: val.pageColor,
                        title: [
                            {
                                text: '七天内考试分数',
                                left: '17%',
                                bottom:0,
                                textStyle: {
                                    color: '#fff' // 这里设置标题颜色为红色
                                }
                            },
                            {
                                text: '全部考试分数',
                                right: '18%',
                                bottom:0,
                                textStyle: {
                                    color: '#fff' // 这里设置标题颜色为红色
                                }
                            }
                        ],
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            right: 30,
                            top: 0,
                            bottom: 20,
                            textStyle: {
                                color: "#fff",
                            },
                            itemHeight: 12,
                            icon: 'circle',
                            type: 'plain',
                            data: val.seven.x_data
                        },
                        series: [
                            {
                                center: ["25%", "50%"],
                                name: '七天内考试分数：',
                                type: 'pie',
                                radius: ['38%', '56%'],
                                data: this.setData(val.seven.data, val.seven.x_data),
                                label: {
                                    normal: {
                                        textStyle: {
                                            color: '#00D8FF'
                                        }
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            },
                            {
                                center: ["75%", "50%"],
                                name: '全部考试分数：',
                                type: 'pie',
                                radius: ['38%', '56%'],
                                data: this.setData(val.all.data, val.all.x_data),
                                label: {
                                    normal: {
                                        textStyle: {
                                            color: '#00D8FF' // 设置文本颜色为红色
                                        }
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]

                    }

                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    data() {
        return {
            is1920: false,
            myChart: null
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
    },
    methods: {
        setData(data, x_data) {
            let arr = []
            for (let i = 0; i < data.length; i++) {
                let temp = {
                    value: data[i],
                    name: x_data[i]
                }
                arr[i] = temp
            }
            return arr
        },
        initChart(data) {
            var that = this

            this.myChart = echarts.init(this.$refs.myChart,);
            this.myChart.setOption(
                data
            );
            this.$nextTick(() => {
                console.log("myChart.resize()");
                this.myChart.resize()
            })
        }
    }
};
</script>
<style>
.hover_box {
    display: flex;
    align-items: center;
}

.yuan {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
}

.color_1 {
    background-color: #ACC7FF;
}

.color_2 {
    background-color: #6690FF;
}

.color_3 {
    background-color: #D6FEF7;
}

.color_4 {
    background-color: #36DDF7;
}
</style>

