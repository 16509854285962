<template>
    <!--
    练题模式 ：选择后出答案 和解析 多选出按钮  答题卡：当前 正确 错误 未答
    测题模式 ：       选择后不出答案解析都不出 答题卡：当前 正确 错误 未答
    背题模式 ：全部结果都显示  答题卡：已答
-->
    <div class="exam_page" :class="[is1920 == true ? 'exam_page_1920' : '']">
        <div class="exam_box" :class="[is1920 == true ? 'exam_box_1920' : '']">
            <div class="left">
                <div class="title_box" :class="[is1920 ? 'title_box_1920' : '']">
                    <div class="exam_name">
                        <div class="text1">考试名称：</div>
                        <div class="text2">{{ examInfo.title }}</div>
                    </div>
                    <div>
                        <backBtn></backBtn>
                    </div>
                </div>
                <div class="ques_list">
                    <div class="ques_item" v-for="(item, index) in pageInfo" :key="index" :id="item.domID">
                        <div class="title">
                            <div class="title_text">
                                <span v-if="item.ques_type == 1">单选题</span>
                                <span v-if="item.ques_type == 2">多选题</span>
                                <span v-if="item.ques_type == 3">排序题</span>
                                <span v-if="item.ques_type == 4">判断题</span>
                                <span v-if="item.ques_type == 5">填空题</span>
                                <span v-if="item.ques_type == 6">问答题</span>
                                <div class="line"></div>
                            </div>
                            <div class="title_btn">
                                <div class="title_btn_item">
                                    <!-- <img src="../../img/eaxm_sc1.png" alt="">
                                    <div class="text">收藏</div> -->
                                </div>
                                <div class="title_btn_item">
                                    <!-- <img src="../../img/eaxm_mark1.png" alt="">
                                    <div class="text">标记</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="ques_box">
                            <div class="subject_title" :style="{ fontSize: 18 + fontSizeNum + 'px' }">
                                <div>{{ index + 1 }}.</div>
                                <div v-html="item.question_title"></div>
                            </div>
                            <div class="subject_answer_list" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
                                <div class="subject_answer_item" v-for="(item2, index2) in item.answers" :key="index2">
                                    <!-- 如果此项是正确答案 显示绿色  如果不是正确答案  用户选了 显示红色   没选显示默认颜色-->
                                    <div class="subject_answer_index select_green" v-if="item2.is_item == 1">
                                        {{ item2.item_chr }}
                                    </div>
                                    <div class="subject_answer_text color_green" v-if="item2.is_item == 1"
                                        v-html="item2.item">
                                    </div>
                                    <div class="subject_answer_index " v-if="item2.is_item == 0"
                                        :class="[item2.userIsAnswer == true ? 'select_red' : '']">
                                        {{ item2.item_chr }}
                                    </div>
                                    <div class="subject_answer_text " v-if="item2.is_item == 0" v-html="item2.item"
                                        :class="[item2.userIsAnswer == true ? 'color_red' : '']">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="daan_jiexi">
                            <div class="daan_box">
                                <div class="title_row">
                                    <div class="text_box">答案
                                        <div class="line"></div>
                                    </div>
                                    <div class="check_wrong_box">
                                        <!-- <img src="../../img/wenhao.png" alt="">
                                        <div class="text">本题纠错</div> -->
                                    </div>
                                </div>
                                <div class="daan_detail_box" :style="{ fontSize: 16 + fontSizeNum + 'px' }">
                                    <div class="daan_detail_item">
                                        <div class="text1">正确答案</div>
                                        <div class="text2 text2_1">{{ item.true_answer }}</div>
                                    </div>
                                    <div class="daan_detail_item">
                                        <div class="text1">你的答案</div>
                                        <div class="text2" v-if="item.right_or_wrong == -2">未作答</div>
                                        <div class="text2 color_red" v-if="item.right_or_wrong == 0">{{ item.your_answer }}
                                        </div>
                                        <div class="text2 color_green" v-if="item.right_or_wrong == 1">{{ item.your_answer
                                        }}
                                        </div>
                                    </div>
                                    <div class="daan_detail_item">
                                        <div class="text1">难度</div>
                                        <div class="text2 text2_3">
                                            <el-rate v-model="item.difficulty" disabled text-color="#ff9900">
                                            </el-rate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="daan_box" style="border-top: 1px solid rgba(222, 229, 253, 1);">
                                <div class="title_row">
                                    <div class="text_box">解析
                                        <div class="line"></div>
                                    </div>
                                    <div class="check_wrong_box" @click="JXBoxFlexible(index)">
                                        <img src="../../img/jt_4.png"
                                            :class="[item.isShowJX == false ? 'img_flexible' : '']" alt="">
                                    </div>
                                </div>
                                <div class="jiexi_info_box">
                                    <div class="jiexi_info" :class="[item.isShowJX == false ? 'jiexi_info_close' : '']"
                                        :style="{ fontSize: 16 + fontSizeNum + 'px' }" v-html="item.analysis">
                                    </div>
                                    <!-- <div class="add_book">添加笔记</div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="right">
                <div class="tool_box" :class="[is1920 == true ? 'tool_box_1920' : '']">
                    <div class="row_1">
                        <div class="report_btn" @click="reportWrong">
                            <img src="../../img/index_exit2.png" alt="">
                            <div>导出错题</div>
                        </div>
                        <div class="report_btn" @click="reportAll">
                            <img src="../../img/index_exit3.png" alt="">
                            <div>导出全卷</div>
                        </div>
                    </div>
                    <div class="time">
                        {{ examInfo.take_time }}
                    </div>
                    <div class="exam_count_box">
                        <div>
                            <span class="text1">正确</span>
                            <span class="text2">{{ examInfo.ques_right }}</span>
                        </div>
                        <div>
                            <span class="text1">错误</span>
                            <span class="text3">{{ examInfo.ques_wrong }}</span>
                        </div>
                    </div>
                </div>

                <div class="card_box" :class="[is1920 == true ? 'card_box_1920' : '']">
                    <div class="title">答题卡</div>
                    <div class="card_type_list">
                        <div class="card_type_item">
                            <div class="block block_1"></div>
                            <div class="text">当前</div>
                        </div>
                        <div class="card_type_item">
                            <div class="block block_2"></div>
                            <div class="text">正确</div>
                        </div>
                        <div class="card_type_item">
                            <div class="block block_3"></div>
                            <div class="text">错误</div>
                        </div>
                        <div class="card_type_item">
                            <div class="block block_4"></div>
                            <div class="text">未答</div>
                        </div>
                    </div>
                    <div class="card_list">
                        <div class="card_item" :class="[cardIndex == index ? 'card_item_select' : '']"
                            v-for="(item, index) in pageInfo" :key="index" @click="changeCardIndex(index)">
                            <div class="text bg_red" v-if="item.right_or_wrong == 0">{{ index + 1 }}</div>
                            <div class="text bg_green" v-if="item.right_or_wrong == 1">{{ index + 1 }}</div>
                            <div class="text bg_blue" v-if="item.right_or_wrong == -2">{{ index + 1 }}</div>

                            <div class="bg bg_red" v-if="item.right_or_wrong == 0"></div>
                            <div class="bg bg_green" v-if="item.right_or_wrong == 1"></div>
                            <div class="bg bg_blue" v-if="item.right_or_wrong == -2"></div>


                            <span class="text2" v-if="item.ques_type == 1"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">单</span>
                            <span class="text2" v-if="item.ques_type == 2"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">多</span>
                            <span class="text2" v-if="item.ques_type == 3"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">排</span>
                            <span class="text2" v-if="item.ques_type == 4"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">判</span>
                            <span class="text2" v-if="item.ques_type == 5"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">填</span>
                            <span class="text2" v-if="item.ques_type == 6"
                                :class="[item.right_or_wrong == 0 || item.right_or_wrong == 1 ? 'color_fff' : '', item.right_or_wrong == -2 ? 'color_blue' : '']">问</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>



        <div class="page_end_fixed">
            <endBox></endBox>
        </div>
    </div>
</template>

<script>

import backBtn from '../../components/backBtn.vue';
import { _getWrongList, _reportSubject } from "../../api/lookSubject"
export default {

    components: {
        backBtn
    },
    created() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
        } else {
            this.is1920 = false
        }
        if (window.localStorage.getItem("lookObjectExamID")) {
            this.examID = window.localStorage.getItem("lookObjectExamID")
        }
        this.getWrong()
    },

    mounted() { },
    data() {
        return {
            cardIndex: 0,
            is1920: false,
            value: 3,
            pageInput: "",
            fontSizeNum: 0,
            examID: null,
            pageInfo: [],
            examInfo: {},
        }
    },
    methods: {
        async reportWrong() {
            let params = {
                exam_id: this.examID,
                check_type: 0,
                isShowMsg: true,
                isShowErr: true,
                load: true
            }
            let res = await _reportSubject(params)
            this.downPDF(res.data)
        },
        async reportAll() {
            let params = {
                exam_id: this.examID,
                check_type: 1,
                isShowMsg: true,
                isShowErr: true,
                load: true
            }
            let res = await _reportSubject(params)
            this.downPDF(res.data)
            // window.location.href = res.data.download_url
            // window.open(res.data.download_url, '_blank');
        },
        downPDF(data) {

            let url = data.download_url
            let name = data.file_name
            // 发送http请求，将文件链接转换成文件流
            let xhr = new XMLHttpRequest()
            xhr.open('get', url, true)
            // 请求类型
            xhr.responseType = 'blob'
            xhr.onreadystatechange = function () {
                // 获取接口结果
                if (xhr.readyState === 4 && xhr.status === 200) {
                    window.URL = window.URL || window.webkitURL
                    let a = document.createElement('a')
                    let blob = new Blob([xhr.response])
                    // 通过二进制文件创建url
                    let url = window.URL.createObjectURL(blob)
                    a.href = url
                    a.download = name
                    a.click()
                    // 销毁创建的url
                    window.URL.revokeObjectURL(url)
                }
            }
            xhr.send()

        },
        async getWrong() {

            let params = {
                exam_id: this.examID,
                check_type: 1,
                isShowMsg: true,
                load: true
            }
            let res = await _getWrongList(params)
            this.pageInfo = this.setData(res.data.ques)
            this.examInfo = res.data.exam_info

        },
        JXBoxFlexible(index) {
            //
            this.pageInfo[index].isShowJX = this.pageInfo[index].isShowJX == true ? false : true
            console.log(this.pageInfo[index].isShowJX);
        },
        setData(data) {
            for (let i = 0; i < data.length; i++) {
                data[i].isShowJX = true;
                data[i].domID = "ques_item_" + i;
                if (data[i].answer) {
                    let tempStr = data[i].answer
                    let tempArr = tempStr.split(",")
                    for (let j = 0; j < tempArr.length; j++) {
                        let tempArr2 = data[i].answers
                        tempArr2.findIndex((item) => {
                            if (item.serical == parseInt(tempArr[j])) {
                                item.userIsAnswer = true
                            } else {
                                item.userIsAnswer = false
                            }
                        });
                    }
                }
            }
            return data
        },
        changeCardIndex(index) {
            this.cardIndex = parseInt(index)
            let tempDomID = this.pageInfo[index].domID
            const el = document.getElementById(tempDomID);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    },
};
</script>
<style scoped>
.exam_page {
    width: 1200px;
    height: 91% !important;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.exam_page_1920 {
    height: 83%;
}

.exam_box {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

}

.exam_box_1920 {
    margin-top: 50px !important;
}

.exam_box .left {
    width: 853px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: #f5f7fb;

}

.exam_page .title_box {
    flex-shrink: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: #fff;
    margin-bottom: 10px;
    font-size: 16px;
}

.title_box_1920 {
    height: 80px !important;
    font-size: 14px !important;
}

.exam_page .title_box .exam_name {
    display: flex;
    align-items: center;
}

.exam_page .title_box .exam_name .text1 {
    color: rgba(114, 123, 142, 1);
    font-size: 14px;
}

.exam_page .title_box .exam_name .text2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
}

.ques_list {
    width: 100%;
    flex: 1;
    overflow-y: auto;

}

.ques_item {
    width: 100%;
    background-color: #fff;
    margin-bottom: 16px;
    box-sizing: border-box;
    padding: 16px;
}

.ques_item .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0 10px 10px;
    border-bottom: 1px solid rgba(222, 229, 253, 1);
}

.ques_item .title .title_text {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.ques_item .title .title_text .line {
    width: 4px;
    height: 20px;
    border-radius: 11px;
    background: rgba(100, 110, 255, 1);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
}

.ques_item .title .title_btn,
.ques_item .title .title_btn .title_btn_item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.title_btn_item {
    margin-left: 26px;
}

.title_btn_item img {
    width: 24px;
    height: 24px;
}

.title_btn_item .text {
    color: rgba(114, 123, 142, 1);
    font-size: 16px;
    margin-left: 8px;
}

.ques_box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;

}

.ques_box .subject_title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 18px;
    display: flex;
}

.ques_box .subject_title div:first-child {
    margin-right: 30px;
}

.subject_answer_list {
    margin-top: 45px;
    box-sizing: border-box;
    padding: 0 10px 20px 10px;
    font-size: 16px;
}

.subject_answer_item {
    display: flex;
    align-items: center;
    padding: 20px 10px;

}

.subject_answer_list .subject_answer_item:nth-of-type(2n+1) {
    background: rgba(238, 242, 254, 1);
}

.subject_answer_list .subject_answer_item:nth-of-type(2n) {
    background: rgba(249, 250, 255, 1);
}

.subject_answer_index {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(222, 229, 253, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    flex-shrink: 0;
}

.subject_answer_index_select {
    background: rgba(100, 110, 255, 1) !important;
    color: #fff !important;
}

.subject_answer_text {
    color: rgba(29, 37, 54, 1);

    color: rgba(29, 37, 54, 1);
    word-wrap: break-word;
    /* 允许在长单词内部换行 */
    word-break: break-all;
    /* 允许在任意位置换行 */
}

.subject_answer_text_select {
    color: rgba(100, 110, 255, 1) !important;
}

.daan_jiexi {
    width: 100%;
    box-sizing: border-box;

    border-top: 1px solid rgba(222, 229, 253, 1);

}

.daan_box {

    width: 100%;
}

.daan_jiexi .title_row {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.daan_box .title_row .text_box {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 18px;
    position: relative;

}

.title_row .text_box .line {
    width: 4px;
    height: 20px;
    border-radius: 11px;
    background: rgba(222, 229, 253, 1);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
}

.check_wrong_box {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.check_wrong_box img {
    width: 15px;
    height: 15px;
    transition: all 0.1s linear;
    transform: rotate(180deg);
}

.check_wrong_box .img_flexible {
    transform: rotate(0deg);
}

.check_wrong_box .text {
    color: rgba(60, 156, 255, 1);
    font-size: 12px;
}

.daan_detail_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 30px 12px 30px;
    font-size: 16px;
}

.daan_detail_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daan_detail_item .text1 {
    margin: 10px 0;
    color: rgba(29, 37, 54, 1);

}

:deep(.el-rate__icon) {
    margin-right: 2px;
}

.daan_detail_item .text2 {
    font-weight: 500;
}

.daan_detail_item .text2_1 {
    color: rgba(41, 203, 149, 1);
}

.daan_detail_item .text2_2 {
    color: rgba(255, 95, 95, 1);
}

.daan_count_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
    box-sizing: border-box;
    padding: 0 4px;
    font-size: 14px;
}

.check_wrong_box img {
    width: 24px;
    height: 24px;
}

.daan_count_item {

    display: flex;
}

.daan_count_item .text1 {
    color: rgba(114, 123, 142, 1);
}

.daan_count_item .text2 {
    color: rgba(22, 27, 38, 1);
}

.jiexi_info_box {
    width: 100%;
    position: relative;
    padding-bottom: 30px;
}

.jiexi_info {
    color: rgba(29, 37, 54, 1);
    font-size: 16px;
    margin-top: 10px;
}

.add_book {
    cursor: pointer;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 10px;
    border: 0.5px solid rgba(60, 156, 255, 1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    font-size: 12px;
    color: rgba(60, 156, 255, 1);
    position: absolute;
    right: 0;
    bottom: 0;
}

.exam_box .right {
    flex: 1;
    height: 100%;

    margin-left: 16px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.exam_box .right .tool_box {
    width: 100%;
    height: 170px;
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.tool_box_1920 {
    height: 200px !important;
    flex: none !important;
}


.tool_box {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 28px 20px 28px;
    background-color: #fff;
}

.tool_box .row_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tool_box .row_1 .report_btn {
    flex: 1;
    height: 42px;
    box-sizing: border-box;
    text-align: center;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.tool_box .row_1 .report_btn:nth-of-type(1) {
    border: 1px solid #FF5F5F;
    color: #FF5F5F;
    margin-right: 8px;
}

.tool_box .row_1 .report_btn:nth-of-type(2) {
    border: 1px solid #000;
    color: #000;
    margin-left: 8px;
}

.tool_box .row_1 .report_btn img {
    width: 24px;
    height: 24px;
}

.tool_box .row_1 .report_btn div {
    margin-left: 6px;
}

.tool_box .time {
    color: #000000;
    font-weight: 500;
    font-size: 36px;
}

.tool_box .exam_count_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 60px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.tool_box .exam_count_box .text1 {
    color: #000000;

}

.tool_box .exam_count_box .text2 {
    color: #29CB95;

}

.tool_box .exam_count_box .text3 {
    color: #FF5F5F;

}

.exam_box .right .card_box {
    width: 100%;
    flex: 1;
    flex-shrink: 0;
    min-height: 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px 18px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.card_box_1920 {
    padding: 20px 18px 25px 18px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card_box .title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 18px;
}

.card_type_list {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 8px;
}

.card_type_item {
    display: flex;
    align-items: center;
}

.card_type_item .block {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    margin-right: 4px;
}

.card_type_item .text {
    color: rgba(29, 37, 54, 1);
    font-size: 14px;
}

.block_1 {
    border: 1px solid rgba(100, 110, 255, 1) !important;
}

.block_2 {
    border: 1px solid rgba(41, 203, 149, 1);
    background: rgba(41, 203, 149, 1);
}

.block_3 {
    border: 1px solid rgba(255, 201, 201, 1);
    background: rgba(255, 201, 201, 1);
}

.block_4 {
    border: 1px solid rgba(222, 229, 253, 1);
    background: rgba(222, 229, 253, 1);
}

.card_list {
    display: flex;
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    margin-top: 10px;
    border-bottom: 1px solid rgba(222, 229, 253, 1);
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
    min-height: 0;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background: rgba(230, 230, 232, 1);
    border-radius: 0;
}

*::-webkit-scrollbar-track {
    background-color: #fff;
    border: 1px solid #fff;
}

.card_item {
    width: 47px;
    height: 47px;
    border-radius: 4px;
    border: 1px solid transparent;
    margin-right: 9px;
    margin-bottom: 9px;
    position: relative;
    cursor: pointer;
}

.card_item_select {
    border: 1px solid #FF4D35 !important;
}

.card_item .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    z-index: 9;
}

.card_item .text2 {
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 0px;
    z-index: 9;
    font-size: 12px;
}

.card_item .bg {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
}

.page_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.page_box .page_btn {
    width: 80px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    border: 1px solid rgba(155, 178, 250, 1);
}

.page_input {
    display: flex;
    font-size: 14px;
    align-items: center;
}

.page_input span {
    margin: 0 6px;
}

.page_input input {
    width: 30px;
    height: 22px;
    border: 1px solid rgba(155, 178, 250, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page_jump_btn {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    background-color: rgba(100, 110, 255, 1);
    font-size: 10px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-left: 4px;
}

.submit_btn {
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    background: rgba(100, 110, 255, 1);
    margin: 10px auto 0;
    cursor: pointer;
}

.submit_btn_1920 {
    height: 36px;
    line-height: 36px;
}

.page_input input:focus {
    outline: 1px solid rgba(155, 178, 250, 1);
    border-radius: 0;
}

.select_red {
    background-color: rgba(255, 95, 95, 1) !important;
    color: #fff !important;
}

.select_green {
    background-color: rgba(41, 203, 149, 1) !important;
    color: #fff !important;
}

.color_red {
    color: rgba(255, 95, 95, 1) !important;
}

.color_green {
    color: rgba(41, 203, 149, 1) !important;
}

.bg_red {
    background-color: #FFC9C9 !important;
    color: #fff !important;
}

.bg_blue {
    background-color: #DEE5FD;
    color: #000;
}

.bg_green {
    background-color: #29cb95 !important;
    color: #fff !important;
}

.color_fff {
    color: #fff;
}

.color_blue {
    color: #646EFF;
}
</style>
