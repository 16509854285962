<template>
    <div ref="myChart"
        style="width: 100%; height: 100%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
import * as echarts from 'echarts';

export default {
    name: 'MyChart1',
    props: {
        pageData: Array
    },
    data() {
        return {
            is1920: false,
            chartsSize:100,
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
        // this.initChart();
    },
    watch: {
        pageData(newValue, oldValue) {
            // 当myProp的值发生变化时，这个函数会被调用
            console.log("newValue");
            console.log(newValue);
            console.log("oldValue");
            console.log(oldValue);
            this.initChart()
        }
    },
    methods: {
        initChart() {
            const myChart = echarts.init(this.$refs.myChart,);
            myChart.setOption({
                title: {
                    text: '123',
                    show: false
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    itemHeight: 12,
                    icon: 'circle',
                    type: 'plain',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,

                    textStyle: {
                        color: "#fff"
                    },
                    data: [
                        {
                            name: "个人的",
                            itemStyle: {
                                color: 'rgb(11, 236, 214)',
                            }
                        },
                        {
                            name: "平均的",
                            itemStyle: {
                                color: 'rgb(2, 99, 255)',
                            }

                        },
                        {
                            name: "目标线",
                            itemStyle: {
                                color: 'rgb(255, 255, 0)',
                            }

                        },
                    ]
                },
                radar: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#003b69'
                            // width: 1,
                            // type: 'solid'
                        }
                    },
                    indicator: [
                        { text: '单选题', max: 100 },
                        { text: '多选题', max: 100 },
                        { text: '判断题', max: 100 },
                    ],
                    center: ['50%', '63%'],
                    radius: this.chartsSize,
                    splitArea: {
                        areaStyle: {
                            color: ['#061646']
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#003b69'],
                            width: 1
                        }
                    },
                    name: {
                        // 雷达图各类别名称文本颜色
                        textStyle: {
                            color: '#fff',
                        }
                    }
                },


                series: [
                    {
                        type: 'radar',
                        color: [
                        ],
                        tooltip: {
                            trigger: 'item'
                        },
                        areaStyle: {},
                        // data
                        data: [
                            {
                                // value: [100, 30, 100],
                                value: this.pageData[0],
                                name: '个人的',
                                areaStyle: {
                                    color: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                            offset: 0, color: 'transparent' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgba(11, 236, 214,.8)' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                },
                                lineStyle: {
                                    normal: {
                                        color: 'transparent',
                                        // width: 1
                                    }
                                },
                                itemStyle: {
                                    color: 'rgb(11, 236, 214)',
                                }


                            },
                            {
                                // value: [80, 30, 90],
                                value: this.pageData[1],
                                name: '平均的',
                                areaStyle: {
                                    color: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                            offset: 0, color: 'transparent' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgb(2, 99, 255,.8)' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                },
                                lineStyle: {
                                    normal: {
                                        color: 'transparent',
                                        // width: 1
                                    }
                                },
                                itemStyle: {
                                    color: 'rgb(2, 99, 255)',
                                }
                            },
                            {
                                // value: [50, 50, 100],
                                value: this.pageData[2],
                                name: '目标线',
                                areaStyle: {
                                    color: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                            offset: 0, color: 'transparent' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgb(255, 255, 0,.8)' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                },
                                lineStyle: {
                                    normal: {
                                        color: 'transparent',
                                        // width: 1
                                    }
                                },
                                itemStyle: {
                                    color: 'rgb(255, 255, 0)',
                                }

                            }
                        ],

                    },

                ]
            });
            this.$nextTick(() => {
                console.log("myChart.resize()");
                myChart.resize()
            })
        }
    }
};
</script>
<style></style>

