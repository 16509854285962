export default class DownTimer {
  stub = null;
  endTime = 0;
  callback = null;

  constructor(endTime, callback) {
    this.endTime = endTime;
    this.callback = callback;
  }

  start() {
    this.stub = setInterval(() => {
      const nowTime = parseInt(Date.now() / 1000);
      let time = this.endTime - nowTime;
      time = time <= 0 ? 0 : time;
      const format = [
        parseInt(time / 3600).toString().padStart(2, '0'),
        parseInt(time % 3600 / 60).toString().padStart(2, '0'),
        parseInt(time % 60).toString().padStart(2, '0'),
      ];
      this.callback && this.callback({
        time,
        format
      });
    }, 1000);
  }

  stop() {
    clearInterval(this.stub);
    this.callback = null;
  }
}
