import { request } from "../utils/request"

// 考试的结果页面
export function _examResult(data) {
    return request({
        url: 'stuwebapi/study.exam/examResult',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}


// 单科练习的结果页面
export function _dklxResult(data) {
    return request({
        url: 'stuwebapi/train.data_list/quesResult',
        method: 'post',
        data,
        isLoading: data.load,
        isShowMsg: data.isShowMsg,
        isShowErr: data.isShowErr
    })
}

