export default class Timer {
  static COUNT = 'count';
  static COUNT_DOWN = 'countDown';

  stub = null;
  benchTime = 0;
  callback = null;
  type = null;

  constructor(benchTime, type, callback) {
    this.benchTime = benchTime;
    this.type = type;
    this.callback = callback;
  }

  start() {
    this.stub = setInterval(() => {
      const nowTime = parseInt(Date.now() / 1000);
      let time = 0;
      if (this.type == Timer.COUNT) {
        time = nowTime - this.benchTime;
      } else if (this.type == Timer.COUNT_DOWN) {
        time = this.benchTime - nowTime;
      }
      time = time <= 0 ? 0 : time;
      const format = [
        parseInt(time / 3600).toString().padStart(2, '0'),
        parseInt(time % 3600 / 60).toString().padStart(2, '0'),
        parseInt(time % 60).toString().padStart(2, '0'),
      ];
      this.callback && this.callback({
        time,
        format
      });
    }, 1000);
  }

  stop() {
    clearInterval(this.stub);
    this.callback = null;
  }
}
