<template>
    <div ref="myChart" id="charts5"
        style="width: 100%; height: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></div>
</template>
   
<script>
var that = this
import * as echarts from 'echarts';
// prettier-ignore

// prettier-ignore
// const data = [
//     [0, 0, 5], [0, 1, 1], [0, 2, 2], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2],
//     [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3],
//     [0, 22, 50], [0, 23, 100]]
//     .map(function (item) {
//         return [item[1], item[0], item[2] || '-'];
//     });
export default {
    name: 'MyChart5',
    props: {
        pageData: Object
    },
    data() {
        return {
            is1920: false,
            myChart: null
        }
    },
    mounted() {
        if (parseInt(window.screen.width) >= 1920) {
            this.is1920 = true
            this.chartsSize = 150
        } else {
            this.is1920 = false
            this.chartsSize = 100
        }
        // this.initChart();

    },
    watch: {
        immediate: true,
        deep: true,
        pageData: {
            handler: function (val, oldval) {

                if (val.pageInfo) {

                    this.pageInfo = val.pageInfo
                    this.pageColor = val.pageColor
                    this.smooth = val.smooth
                    this.XYName= val.XYName
                    this.isShowColor = val.isShowColor
                    this.chartsInfo = {
                        color: val.pageColor,
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        // legend: {
                        //     data: ['个人总计平均时长', '每天总计时长', '大家当日平均时长', '每天有效时长'],
                        //     formatter: function (data) {
                        //         // 自定义换行
                        //         return data.split('\n').join('\n');
                        //     }

                        // },
                        legend: {

                            right: 30,
                            top: 10,
                            bottom: 20,


                            textStyle: {
                                color: "#fff",

                            },
                            data: this.setLegendData(val.pageInfo.title, val.pageColor)
                        },
                        toolbox: {
                            feature: {
                                // saveAsImage: {}
                            }
                        },
                        grid: {
                            left: '5%',
                            right: '8%',
                            top: '18%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            name: val.XYName.x,
                            type: 'category',
                            boundaryGap: false,
                            data: val.pageInfo.x_data

                        },
                        yAxis: {
                            name: val.XYName.y,
                            type: 'value',
                            inverse: this.isShowColor || false,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'solid',
                                    color: '#1c2962'
                                }
                            },
                            nameGap: this.getNameGap()
                        },
                        series: this.setData(val.pageInfo.title, val.pageInfo.data, val.pageColor)
                        // series: [
                        //     {
                        //         name: '个人总计平均时长',
                        //         type: 'line',
                        //         // stack: 'Total',
                        //         smooth: true,
                        //         symbol: 'circle', //变为实心圆
                        //         lineStyle: {
                        //             width: 2,
                        //             type: 'solid',
                        //             color: "#41ABFF"
                        //         },
                        //         showSymbol: false,
                        //         areaStyle: {
                        //             opacity: 0.8,

                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 {
                        //                     offset: 0,
                        //                     color: ' #41ABFF'
                        //                 },
                        //                 {
                        //                     offset: 1,
                        //                     color: ' #6690ff00'
                        //                 }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             focus: 'series'
                        //         },
                        //         data: [1, 2, 3, 4, 5, 6, 7]
                        //     },
                        //     {
                        //         name: '每天总计时长',
                        //         type: 'line',
                        //         // stack: 'Total',
                        //         smooth: true,
                        //         symbol: 'circle', //变为实心圆
                        //         lineStyle: {
                        //             width: 2,
                        //             type: 'solid',
                        //             color: "#7737FF"
                        //         },
                        //         showSymbol: false,
                        //         areaStyle: {
                        //             opacity: 0.8,

                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 {
                        //                     offset: 0,
                        //                     color: '#7737FF'
                        //                 },
                        //                 {
                        //                     offset: 1,
                        //                     color: '#6690ff00'
                        //                 }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             focus: 'series'
                        //         },
                        //         data: [8, 9, 10, 11, 12, 13, 56]
                        //     },
                        //     {
                        //         name: '大家当日平均时长',
                        //         type: 'line',
                        //         // stack: 'Total',
                        //         smooth: true,
                        //         symbol: 'circle', //变为实心圆
                        //         lineStyle: {
                        //             width: 2,
                        //             type: 'solid',
                        //             color: "#22FFCD"
                        //         },
                        //         showSymbol: false,
                        //         areaStyle: {
                        //             opacity: 0.8,

                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 {
                        //                     offset: 0,
                        //                     color: '#22FFCD'
                        //                 },
                        //                 {
                        //                     offset: 1,
                        //                     color: '#6690ff00'
                        //                 }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             focus: 'series'
                        //         },
                        //         data: [15, 16, 17, 18, 19, 20, 21]
                        //     },
                        //     {
                        //         name: '每天有效时长',
                        //         type: 'line',
                        //         // stack: 'Total',
                        //         smooth: true,
                        //         symbol: 'circle', //变为实心圆
                        //         lineStyle: {
                        //             width: 2,
                        //             type: 'solid',
                        //             color: "#6690FF"
                        //         },
                        //         showSymbol: false,
                        //         areaStyle: {
                        //             opacity: 0.8,

                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 {
                        //                     offset: 0,
                        //                     color: ' #6690FF'
                        //                 },
                        //                 {
                        //                     offset: 1,
                        //                     color: ' #6690ff00'
                        //                 }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             focus: 'series'
                        //         },
                        //         data: [22, 23, 24, 25, 26, 27, 29]
                        //     },
                        // ]
                    }
                    this.initChart(this.chartsInfo);
                }

            },
            deep: true//对象内部的属性监听，也叫深度监听  
        }
    },
    methods: {
        getNameGap(){
            if(this.isShowColor){
                return -250
            }else {
                return 20
            }
        },
        setLegendData(dataTitle, dataColor) {
            let arr = []
            for (let i = 0; i < dataTitle.length; i++) {
                let temp = {
                    icon: 'circle',
                    name: dataTitle[i],
                    itemStyle: {
                        color: dataColor[i],
                    }
                }
                arr[i] = temp
            }
            return arr
        },
        setData(title, data, pageColor) {
            let arr = []

            for (let i = 0; i < data.length; i++) {
                let temp = {
                    name: title[i],
                    type: 'line',
                    // stack: 'Total',
                    smooth: this.smooth,
                    symbol: 'circle', //变为实心圆
                    lineStyle: {
                        width: 2,
                        type: 'solid',
                        color: pageColor[i]
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,

                        color:this.isShowColor!=true? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: pageColor[i]
                            },
                            {
                                offset: 1,
                                color: ' #6690ff00'
                            }
                        ]):new echarts.graphic.LinearGradient(0, 0, 0, 1, [

                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data[i]
                }
                arr[i] = temp
            }
            return arr
            // val.pageInfo.title,val.pageInfo.data,val.pageColor
        },
        initChart(data) {
            var that = this

            this.myChart = echarts.init(this.$refs.myChart,);
            this.myChart.setOption(
                data
                // {
                //     color: ['#41ABFF', '#7737FF', '#22FFCD', '#6690FF'],
                //     title: {
                //         text: ''
                //     },
                //     tooltip: {
                //         trigger: 'axis',
                //         axisPointer: {
                //             type: 'cross',
                //             label: {
                //                 backgroundColor: '#6a7985'
                //             }
                //         }
                //     },
                //     // legend: {
                //     //     data: ['个人总计平均时长', '每天总计时长', '大家当日平均时长', '每天有效时长'],
                //     //     formatter: function (data) {
                //     //         // 自定义换行
                //     //         return data.split('\n').join('\n');
                //     //     }

                //     // },
                //     legend: {

                //         right: 30,
                //         top: 10,
                //         bottom: 20,


                //         textStyle: {
                //             color: "#fff",

                //         },
                //         data: [
                //             {
                //                 icon: 'circle',
                //                 name: "个人总计平均时长",
                //                 itemStyle: {
                //                     color: '#41ABFF',
                //                 }
                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "每天总计时长",
                //                 itemStyle: {
                //                     color: '#7737FF',
                //                 }

                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "大家当日平均时长",
                //                 itemStyle: {
                //                     color: '#22FFCD',
                //                 }

                //             },
                //             {
                //                 icon: 'circle',
                //                 name: "每天有效时长",
                //                 itemStyle: {
                //                     color: '#6690FF',
                //                 }

                //             }
                //         ]

                //     },
                //     toolbox: {
                //         feature: {
                //             // saveAsImage: {}
                //         }
                //     },
                //     grid: {
                //         left: '3%',
                //         right: '4%',
                //         bottom: '3%',
                //         containLabel: true
                //     },
                //     xAxis: {
                //         type: 'category',
                //         boundaryGap: false,
                //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

                //     },
                //     yAxis: {
                //         type: 'value',
                //         splitLine: {
                //             show: true,
                //             lineStyle: {
                //                 type: 'solid',
                //                 color: '#1c2962'
                //             }
                //         }
                //     },
                //     series: [
                //         {
                //             name: '个人总计平均时长',
                //             type: 'line',
                //             // stack: 'Total',
                //             smooth: true,
                //             symbol: 'circle', //变为实心圆
                //             lineStyle: {
                //                 width: 2,
                //                 type: 'solid',
                //                 color: "#41ABFF"
                //             },
                //             showSymbol: false,
                //             areaStyle: {
                //                 opacity: 0.8,

                //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //                     {
                //                         offset: 0,
                //                         color: ' #41ABFF'
                //                     },
                //                     {
                //                         offset: 1,
                //                         color: ' #6690ff00'
                //                     }
                //                 ])
                //             },
                //             emphasis: {
                //                 focus: 'series'
                //             },
                //             data: [1, 2, 3, 4, 5, 6, 7]
                //         },
                //         {
                //             name: '每天总计时长',
                //             type: 'line',
                //             // stack: 'Total',
                //             smooth: true,
                //             symbol: 'circle', //变为实心圆
                //             lineStyle: {
                //                 width: 2,
                //                 type: 'solid',
                //                 color: "#7737FF"
                //             },
                //             showSymbol: false,
                //             areaStyle: {
                //                 opacity: 0.8,

                //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //                     {
                //                         offset: 0,
                //                         color: '#7737FF'
                //                     },
                //                     {
                //                         offset: 1,
                //                         color: '#6690ff00'
                //                     }
                //                 ])
                //             },
                //             emphasis: {
                //                 focus: 'series'
                //             },
                //             data: [8, 9, 10, 11, 12, 13, 56]
                //         },
                //         {
                //             name: '大家当日平均时长',
                //             type: 'line',
                //             // stack: 'Total',
                //             smooth: true,
                //             symbol: 'circle', //变为实心圆
                //             lineStyle: {
                //                 width: 2,
                //                 type: 'solid',
                //                 color: "#22FFCD"
                //             },
                //             showSymbol: false,
                //             areaStyle: {
                //                 opacity: 0.8,

                //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //                     {
                //                         offset: 0,
                //                         color: '#22FFCD'
                //                     },
                //                     {
                //                         offset: 1,
                //                         color: '#6690ff00'
                //                     }
                //                 ])
                //             },
                //             emphasis: {
                //                 focus: 'series'
                //             },
                //             data: [15, 16, 17, 18, 19, 20, 21]
                //         },
                //         {
                //             name: '每天有效时长',
                //             type: 'line',
                //             // stack: 'Total',
                //             smooth: true,
                //             symbol: 'circle', //变为实心圆
                //             lineStyle: {
                //                 width: 2,
                //                 type: 'solid',
                //                 color: "#6690FF"
                //             },
                //             showSymbol: false,
                //             areaStyle: {
                //                 opacity: 0.8,

                //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //                     {
                //                         offset: 0,
                //                         color: ' #6690FF'
                //                     },
                //                     {
                //                         offset: 1,
                //                         color: ' #6690ff00'
                //                     }
                //                 ])
                //             },
                //             emphasis: {
                //                 focus: 'series'
                //             },
                //             data: [22, 23, 24, 25, 26, 27, 29]
                //         },
                //     ]
                // }
            );
            this.$nextTick(() => {
                console.log("myChart.resize()");
                this.myChart.resize()
            })
        }
    }
};
</script>
<style></style>

