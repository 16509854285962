<template>
    <div class="app_page">
        <div class="main_box">
            <div class="title_box">
                <div class="left">
                    <div class="page_btn" :class="[pageTabIndex == index ? 'btn_select' : '']"
                        v-for="(item, index) in examType" :key="index" @click="changePaegIndex(index)">{{ item }}</div>
                </div>
                <div class="right">
                    <backBtn></backBtn>
                </div>
            </div>
            <div class="list_box" v-if="pageTabIndex == 0" @scroll="TKhandleScroll">
                <div class="list_item" v-for="(item, index) in MKListData" :key="index">
                    <div class="col_1">{{ item.sub_name }}</div>
                    <div class="col_2">{{ item.score }}分</div>
                    <div class="col_3">{{ item.time | dateFormat }}</div>
                    <div class="col_4">{{ item.duration }}分钟</div>
                    <div class="col_5">
                        <div class="page_btn btn_select" @click="loogWrong(0, index)">查看错题</div>
                        <div class="page_btn btn_select" @click="loogAll(0, index)">查看全卷</div>
                    </div>
                </div>
            </div>
            <div class="list_box" v-if="pageTabIndex == 1" @scroll="TKhandleScroll">
                <div class="list_item" v-for="(item, index) in TKListData" :key="index">
                    <div class="col_1">{{ item.title }}</div>
                    <div class="col_2">{{ item.score }}分</div>
                    <div class="col_3">{{ item.start_time | dateFormat }}</div>
                    <div class="col_4">{{ item.duration }}分钟</div>
                    <div class="col_5">
                        <div v-if="item.ques_show" class="page_btn btn_select" @click="loogWrong(1, index)">查看错题</div>
                        <div v-if="item.ques_show" class="page_btn btn_select" @click="loogAll(1, index)">查看全卷</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import backBtn from "../../components/backBtn.vue"
import { _getMKResultList, _getTKResultList } from "../../api/lookSubject"
export default {

    components: { backBtn },
    created() {
        this.getMKList()
        this.getTKList()
    },

    destroyed() {

    },

    mounted() {
        this.pageTabIndex = window.localStorage.getItem("lookIndex")
    },
    data() {
        return {
            pageTabIndex: 0,
            examType: ["模考成绩", "统考成绩"],
            MKListData: [],
            MKDataLength: 0,
            MKPageNumber: 1,

            TKListData: [],
            TKDataLength: 0,
            TKPageNumber: 1,


        };
    },
    methods: {
        TKhandleScroll(event) {
            const dialog = event.target;
            if (dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight) {
                console.log("滚动到底了");
                if (this.MKListData.length >= this.MKDataLength) {
                    console.log("数据没了");
                    return false
                }
                this.MKPageNumber = this.MKPageNumber + 1
                this.getMKList()
            }

        },
        async getMKList() {
            let params = {
                pro_id: window.localStorage.getItem("proID"),
                page: this.MKPageNumber,
                load:true
            }
            let res = await _getMKResultList(params)
            console.log("模考成绩列表");
            console.log(res.data.data);
            if (this.MKPageNumber == 1) {

                this.MKListData = res.data.data
                this.MKDataLength = res.data.total
            } else {
                this.MKListData = this.MKListData.concat(res.data.data)
                this.MKDataLength = res.data.total
            }
        },
        TKhandleScroll(event) {
            const dialog = event.target;
            if (dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight) {
                console.log("滚动到底了");
                if (this.TKListData.length >= this.TKDataLength) {
                    console.log("数据没了");
                    return false
                }
                this.TKPageNumber = this.TKPageNumber + 1
                this.getTKList()
            }
        },
        async getTKList() {
            let params = {
                pro_id: window.localStorage.getItem("proID"),
                page: this.TKPageNumber,
                load:true
            }
            let res = await _getTKResultList(params)
            console.log("统考成绩列表");
            console.log(res.data.data);
            if (this.TKPageNumber == 1) {
                this.TKListData = res.data.data
                this.TKDataLength = res.data.total
            } else {
                this.TKListData = this.TKListData.concat(res.data.data)
                this.TKDataLength = res.data.total
            }
        },
        changePaegIndex(index) {
            this.pageTabIndex = parseInt(index)
            window.localStorage.setItem("lookIndex", this.pageTabIndex)

            switch (this.pageTabIndex) {
                case 0:
                    this.MKListData = []
                    this.MKDataLength = 0
                    this.MKPageNumber = 1
                    this.getMKList()
                    break;
                case 1:
                    this.TKListData = []
                    this.TKDataLength = 0
                    this.TKPageNumber = 1
                    this.getTKList()
                    break;
                default:
                    break;
            }
        },
        loogWrong(tabIndex, index) {
            switch (parseInt(tabIndex)) {
                case 0:
                    window.localStorage.setItem("lookObjectExamID", this.MKListData[index].id)
                    break;
                case 1:
                    window.localStorage.setItem("lookObjectExamID", this.TKListData[index].id)
                    break;
                default:
                    break;
            }
            this.$router.push({ name: 'LookWrong' })
        },
        loogAll(tabIndex, index) {
            switch (parseInt(tabIndex)) {
                case 0:
                    window.localStorage.setItem("lookObjectExamID", this.MKListData[index].id)
                    break;
                case 1:
                    window.localStorage.setItem("lookObjectExamID", this.TKListData[index].id)
                    break;
                default:
                    break;
            }
            this.$router.push({ name: 'LookAll', })
        },
    },
};
</script>
<style scoped>
.app_page {
    width: 100%;
    height: 100%;
    position: relative;
}

.main_box {
    width: 1200px;
    height: 93%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px 16px;
}

.title_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(235, 238, 245, 1);
}

.title_box .left {
    display: flex;
}

.page_btn {
    width: 120px;
    height: 44px;
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    background: rgb(205, 216, 253);
    margin-right: 16px;
    cursor: pointer;
}

.btn_select {
    background-color: rgba(100, 110, 255, 1) !important;
}

.list_box {
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.list_item {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(235, 238, 245, 1);
}

.list_box .list_item:last-child {
    border-bottom: none;
}

.list_item>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_item .col_1 {
    width: 350px;
    color: rgba(22, 27, 38, 1);
    font-weight: 700;
    font-size: 18px;
    justify-content: flex-start !important;
}

.list_item .col_2 {
    flex: 1;
    color: rgba(255, 95, 95, 1);
    font-weight: 500;
    font-size: 18px;
}

.list_item .col_3,
.list_item .col_4 {
    flex: 1;
    color: rgba(114, 123, 142, 1);
    font-weight: 500;
    font-size: 14px;
}

.list_item .col_4 {}

.list_item .col_5 {
    width: 260px;
    height: 44px;
    display: flex;
    justify-content: space-between;
}
</style>
  

<!-- border-bottom: 1px solid rgba(235, 238, 245, 1); -->