<template>
  <div>首页</div>
</template>

<script>
export default {
  components: {},
  created() {},

  mounted() {},
  data() {
    return {

    };
  },
  methods: {},
};
</script>
<style scoped>

</style>
