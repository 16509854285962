<template>
  <div class="back_btn" @click="backPage">
    <img src="https://resources.sxsaas.com/static/jigou-saas-app/jiantou.png" alt="">
    <div class="text">返回</div>
  </div>
</template>
  

<script>

export default {
  name: "backBtn",
  components: {},
  created() {

  },

  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {
    backPage(){
      this.$router.back()
    }
  },
};
</script>

<style scoped>
.back_btn {
  width: 95px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(122, 152, 249, 1);
  border-radius: 4px;
  cursor: pointer;
}

.back_btn img {
  width: 20px;
  height: 20px;
  color: rgba(29, 37, 54, 1);
  font-size: 18px;

}

.back_btn .text {
  margin-top: -1px;
  margin-left: 8px;
}
</style>

